import React, { useState, useEffect} from "react";
import { DROPDOWN_URL, DYNAMIC_URL } from "../../../constants/api";
import {postAPI, getAPI} from '../../../utils/apiRequest/index';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import { Form, Select, Input, Button, Row, Col, Card } from 'antd';


const AddDynamicSetting = () => {
  const [formData, setFormData] = useState({
    name: "",
    value: ""
  });

  const [form] = Form.useForm();
  const [nameList, setName] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getName();
  }, []);

  const getName = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
    };

    let successFn = function (result) {
      setName(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(DROPDOWN_URL, successFn, errorFn, apiParams);
  };

  const handleInputChange = (type, values) => {
    setFormData((formData)=>{
      return{...formData, [type]:values ? values : null}
    });
  };


  const handleSubmit = async (values) => {
    const { name, value} = values;
    if(name==null || value==null){
        emptyFn();
    }
    else{
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'Dynamic Setting Added Successfully.');
        setLoading(false);
        form.resetFields();
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
        setLoading(false);
    };

    postAPI(DYNAMIC_URL, values, successFn, errorFn);
}
  };

  return (
    <Card style={{padding:24}}>
    <h2 style={{marginBottom: '40px' }}>Add Dynamic Setting</h2>
    <Form onFinish={handleSubmit} labelCol={{ span: 2 }} wrapperCol={{ span: 10 }} form={form}>
    <Row>
    <Col span={24}>
      <Form.Item label="Name" name="name" rules={[{required: true}]}>
      <Select placeholder={"Select Name"} onChange={(value) => handleInputChange("name", value)} optionFilterProp={"children"} showSearch={true}>
          {nameList.map((name) => (
            <Select.Option key={name.title} value={name.title} label={name.title}>
              {name.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label="Value" name={"value"} rules={[{required: true}]}>
        <Input placeholder="Value"/>
      </Form.Item>
    </Col>
    </Row>
    <Col span={24} style={{textAlign:"center"}}>
    <Form.Item>
    <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
      {loading ? 'Adding...' : 'Add'}
    </Button>
  </Form.Item>
</Col>
</Form>
</Card>
  );
};
export default AddDynamicSetting;
