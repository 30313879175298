import React, { useState, useEffect} from 'react';
import { Layout, Menu as AntMenu, theme, Button, Dropdown, Menu } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined, MenuOutlined, SettingOutlined, HomeOutlined, DoubleRightOutlined, DoubleLeftOutlined, ClockCircleOutlined, MessageOutlined } from '@ant-design/icons';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { USER_CLONE, LOGOUT_URL } from "../../../constants/api";
import {getAPI, postAPI} from '../../../utils/apiRequest/index';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE, REFRESH_TOKEN} from '../../../constants/dataKeys';
import {displayMessage, handleErrorResponse} from '../../../utils/common';
import {get} from 'lockr';
import '../../../index.css';
import AddDynamicSetting from '../DynamicSettings/AddDynamicSetting';
import ViewDynamicSetting from '../DynamicSettings/ViewDynamicSetting';
import AddEmployee from '../Employee/AddEmployee';
import UpdateEmployee from '../Employee/UpdateEmployee';
import ViewEmployee from '../Employee/ViewEmployee';
import EmployeeTaskReport from '../Employee/EmployeeTaskReport';
import EmployeeWorkLog from '../Employee/EmployeeWorkLog';
import EmployeeTimeSheet from '../Employee/EmployeeTimeSheet';
import AddProject from '../Project/AddProject';
import ViewProject from '../Project/ViewProject';
import UpdateProject from '../Project/UpdateProject';
import AddTask from '../Task/AddTask';
import ViewTask from '../Task/ViewTask';
import ViewCompletedTask from "../Task/ViewCompletedTask";
import UpdateTask from '../Task/UpdateTask';
import UpdateTaskEmployee from '../Task/UpdateTaskEmployee';
import ViewTaskModification from "../Task/TaskModificationHistory";
import ChangePassword from '../User/ChangePassword';
import NotificationDropdown from "./NotificationDropdown";
import Notification from "../Employee/Notification";
import AddWorkLog from "../WorkLog/AddWorkLog";
import ViewWorkLog from "../WorkLog/ViewWorkLog";
import UpdateWorkLog from "../WorkLog/UpdateWorkLog";
import AddTaskComment from "../Task_Comments/AddTaskComment";
import ViewTaskComment from "../Task_Comments/ViewTaskComment";
import UpdateTaskComment from "../Task_Comments/UpdateTaskComment";
import ViewKanbanBoard from "../Task/KanbanBoard";

const { Header, Sider, Content } = Layout;

const dynamicSettingsItems = [
  { key: '/dashboard/viewdropdown/', label: 'View Dropdown', navigate:"viewdropdown/"},
];

const employeeItems = [
  { key: '/dashboard/viewemployee/', label: 'View Employee', navigate:"viewemployee/"},
  { key: '/dashboard/employeetaskreport/', label: 'Task Report', navigate:"employeetaskreport/"},
  { key: '/dashboard/employeetimesheet/', label: 'Time Sheet', navigate:"employeetimesheet/"},
  { key: '/dashboard/notifications/', label: 'Notifications', navigate:"notifications/"},
];

const projectItems = [
  { key: '/dashboard/viewproject/', label: 'View Project', navigate:"viewproject/"},
];

const taskItems = [
  { key: '/dashboard/addtask/', label: 'Add Task', navigate:"addtask/" },
  { key: '/dashboard/kanbanboard/', label: 'Kanban Board', navigate:"kanbanboard/" },
  { key: '/dashboard/viewtask/', label: 'View Task', navigate:"viewtask/"},
  { key: '/dashboard/viewcompletedtask/', label: 'Completed Task', navigate:"viewcompletedtask/"},
  { key: '/dashboard/viewtaskmodification/', label: 'Task Modification', navigate:"viewtaskmodification/"},
  { key: '/dashboard/updatetaskstatus/', label: 'Status/ Worklog', navigate:"updatetaskstatus/" },
];

const WorkLogItems = [
    { key: '/dashboard/addworklog/', label: 'Add Work Log', navigate:"addworklog/"},
    { key: '/dashboard/viewworklog/', label: 'View Work Log', navigate:"viewworklog/" },
];

const TaskCommentItems = [
    { key: '/dashboard/addtaskcomment/', label: 'Add Task Comment', navigate:"addtaskcomment/"},
    { key: '/dashboard/viewtaskcomment/', label: 'View Task Comment', navigate:"viewtaskcomment/" },
];

const sidebarItems = [
  { icon: <HomeOutlined />, label: 'Dashboard', key: '/dashboard', navigate:"/dashboard"},
  { icon: <SettingOutlined />, label: 'Dynamic Settings', key: 'dynamicSettings', children: dynamicSettingsItems },
  { icon: <UserOutlined />, label: 'Employee', key: 'Employee', dynamicLabel: 'Employee', children: employeeItems },
  { icon: <LaptopOutlined />, label: 'Project', key: 'Project', dynamicLabel: 'Project', children: projectItems },
  { icon: <NotificationOutlined />, label: 'Task', key: 'Task', dynamicLabel: 'Task', children: taskItems },
  { icon: <ClockCircleOutlined />, label: 'Work Log', key: 'Worklog', dynamicLabel: 'Work Log', children: WorkLogItems },
  { icon: <MessageOutlined />, label: 'Task Comment', key: 'Comment', dynamicLabel: 'Task Comment', children: TaskCommentItems },
];

const SidebarNavbar = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [UserData, setUserData] = useState([]);

  if (UserData.is_superuser) {
    if (!dynamicSettingsItems.find(item => item && item.key === '/dashboard/adddropdown/')) {
      dynamicSettingsItems.unshift({ key: '/dashboard/adddropdown/', label: 'Add Dropdown', navigate:"adddropdown/"});
    }
    if (!employeeItems.find(item => item && item.key === '/dashboard/updateemployee/')) {
      employeeItems.unshift({ key: '/dashboard/updateemployee/', label: 'Update Employee', navigate: 'updateemployee/' });
    }
    if (!employeeItems.find(item => item && item.key === '/dashboard/addemployee/')) {
      employeeItems.unshift({ key: '/dashboard/addemployee/', label: 'Add Employee', navigate: 'addemployee/' });
    }
    if (!employeeItems.find(item => item && item.key === '/dashboard/employeeworklog/')) {
          employeeItems.unshift(  { key: '/dashboard/employeeworklog/', label: 'Employee Work Log', navigate:"employeeworklog/"});
    }
    if (!projectItems.find(item => item && item.key === '/dashboard/updateproject/')) {
      projectItems.unshift({ key: '/dashboard/updateproject/', label: 'Update Project', navigate:"updateproject/"});
    }
    if (!projectItems.find(item => item && item.key === '/dashboard/addproject/')) {
      projectItems.unshift({ key: '/dashboard/addproject/', label: 'Add Project', navigate:"addproject/"});
    }
    if (!taskItems.find(item => item && item.key === '/dashboard/updatetask/')) {
      taskItems.unshift({ key: '/dashboard/updatetask/', label: 'Update Task', navigate:"updatetask/" });
    }
    const updateTaskIndex = taskItems.findIndex(item => item.key === '/dashboard/updatetask/');
    if (updateTaskIndex !== -1) {
      taskItems.splice(updateTaskIndex, 1);
      taskItems.splice(1, 0, { key: '/dashboard/updatetask/', label: 'Update Task', navigate: 'updatetask/' });
    }
    const EmployeeWorkLogIndex = employeeItems.findIndex(item => item.key === '/dashboard/employeeworklog/');
      if (EmployeeWorkLogIndex !== -1) {
          employeeItems.splice(EmployeeWorkLogIndex, 1);
          employeeItems.splice(5, 0, { key: '/dashboard/employeeworklog/', label: 'Work Log', navigate:"employeeworklog/"});
      }
  }


  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    let apiParams = {
      pagination: false,
    };

    let successFn = function (result) {
      setUserData(result.user);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
    };
    getAPI(USER_CLONE, successFn, errorFn, apiParams);
  };

  const renderSubMenu = (item) => (
    <AntMenu.SubMenu
      key={item.key}
      icon={item.icon}
      title={item.label}
      onClick={() => { if (item.navigate) navigate(item.navigate); }}
    >
      {item.children && item.children.map(subItem => (
        <AntMenu.Item key={subItem.key} onClick={() => { if (subItem.navigate) navigate(subItem.navigate); }}>
          {subItem.label}
        </AntMenu.Item>
      ))}
    </AntMenu.SubMenu>
  );

  const handleLogout = async () => {
    let successFn = function (result) {
        localStorage.clear('refresh');
        localStorage.clear('access');
        displayMessage(SUCCESS_MSG_TYPE, 'Logout Successfully.');
        navigate("/");
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
    };

    postAPI(LOGOUT_URL, {
            refresh: get(REFRESH_TOKEN)
  }, successFn, errorFn);
};

const toggleSidebar = () => {
  setCollapsed(!collapsed);
};

  const profileMenu = (
    <Menu>
      <Menu.Item key="changePassword" onClick={() => navigate('changepassword/')}><i className="fas fa-key me-2"/> Change Password</Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}><i className="fas fa-sign-out-alt me-2"/> Log Out</Menu.Item>
    </Menu>
  );



  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          padding: 0,
          width: '100%', // Set the width to 100%
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          background: '#31A6C7'
        }}
      >
        <Button type="primary" onClick={toggleSidebar} style={{ marginLeft: 16 }}>
          <MenuOutlined />
        </Button>
        <div style={{ color: '#fff', fontSize: '18px' }}>TASK MANAGER</div>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: '16px', cursor: 'pointer', paddingTop: 5}}>
              <NotificationDropdown/>
            </div>
          <Dropdown overlay={profileMenu} placement="bottomRight">
            <div style={{color: '#fff', fontSize: '18px', cursor: 'pointer', paddingRight: 10}}>
              <UserOutlined/> {UserData.first_name + ' ' + (UserData.last_name ? UserData.last_name : "")}
            </div>
          </Dropdown>
        </div>
      </Header>
      <Sider
          width={220}
          style={{
            background: colorBgContainer,
            marginRight: collapsed ? '-110px' : '-210px',
            zIndex: 20,
            boxShadow: "initial",
            overflowY: collapsed ? 'hidden' : 'auto',
            overflowX: collapsed ? 'hidden' : 'auto',
            height: '100vh',
          }}
          theme="dark"
          collapsed={collapsed}
      >
        <div onClick={toggleSidebar} style={{marginLeft: 16, marginTop: 15, display: "flex"}}>
          {collapsed ? <DoubleRightOutlined/> : <DoubleLeftOutlined/>}
          {collapsed ? <img alt="logo" src="https://www.plutonic.co.in/img/favicon.png" style={{marginLeft: 10}}/> :
              <img alt="logo" src="https://www.plutonic.co.in/imgs/plutonic_logo.png" style={{marginLeft: 10, height: 40}}/>}
        </div>
        <AntMenu
            mode="inline"
            selectedKeys={[location.pathname]}
          style={{
            height: '100%',
            borderRight: 0,
            paddingTop: 20,
          }}
        >

          {sidebarItems.map(item => (
            item.children ? renderSubMenu(item) : (
              <AntMenu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => { if (item.navigate) navigate(item.navigate); }}
              >
                {item.label}
              </AntMenu.Item>
            )
          ))}
        </AntMenu>
      </Sider>
      <Layout style={{ marginLeft: collapsed ? 100 : 200, transition: 'margin-left 0.3s', background: '#f6f6f6' }}>
        <Content style={{ padding: '24px',marginTop: 50 }}>
          <Routes>
            <Route path="/" element={children} />
            <Route path="adddropdown" element={<AddDynamicSetting/>}/>
            <Route path="viewdropdown" element={<ViewDynamicSetting/>}/>
            <Route path="addemployee" element={<AddEmployee/>}/>
            <Route path="updateemployee" element={<UpdateEmployee/>}/>
            <Route path="viewemployee" element={<ViewEmployee/>}/>
            <Route path="employeetaskreport" element={<EmployeeTaskReport/>}/>
            <Route path="employeeworklog" element={<EmployeeWorkLog/>}/>
            <Route path="employeetimesheet" element={<EmployeeTimeSheet/>}/>
            <Route path="notifications" element={<Notification/>}/>
            <Route path="addproject" element={<AddProject/>}/>
            <Route path="viewproject" element={<ViewProject/>}/>
            <Route path="updateproject" element={<UpdateProject/>}/>
            <Route path="addtask" element={<AddTask/>}/>
            <Route path="kanbanboard" element={<ViewKanbanBoard/>}/>
            <Route path="viewtask" element={<ViewTask/>}/>
            <Route path="viewcompletedtask" element={<ViewCompletedTask/>}/>
            <Route path="viewtaskmodification" element={<ViewTaskModification/>}/>
            <Route path="updatetask" element={<UpdateTask/>}/>
            <Route path="updatetaskstatus" element={<UpdateTaskEmployee/>}/>
            <Route path="addworklog" element={<AddWorkLog/>}/>
            <Route path="viewworklog" element={<ViewWorkLog/>}/>
            <Route path="updateworklog" element={<UpdateWorkLog/>}/>
            <Route path="addtaskcomment" element={<AddTaskComment/>}/>
            <Route path="viewtaskcomment" element={<ViewTaskComment/>}/>
            <Route path="updatetaskcomment" element={<UpdateTaskComment/>}/>
            <Route path="changepassword" element={<ChangePassword/>}/>
          </Routes>
          {/* {children}   */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default SidebarNavbar;