import React, { useState, useEffect } from 'react';
import { DYNAMIC_URL, USER_CLONE } from "../../../constants/api";
import {getAPI, postAPI} from '../../../utils/apiRequest/index';
import { handleErrorResponse } from "../../../utils/common";
import { Table, Spin, Input, Button, Popconfirm } from 'antd';
import { ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import CustomPagination from "../../common/CustomPagination";
import {DEFAULT_PAGE_SIZE} from "../../../constants/dataKeys";

const ViewDynamicSetting = () => {
  const [dynamicData, setDynamicData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterKeys, setFilterKeys] = useState({});
  const [UserData, setUserData] = useState([]);
  const [count, setCount] = useState(1);
  const [nextPage, setnextPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleReset = () => {
    setFilterKeys({});
  };

  const filterData = (type, value) => {
    setFilterKeys((filterKeys) => {
        return {...filterKeys, [type]: value ? value : undefined};
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    getDynamicData();
  }, [filterKeys]);

  const onDelete = (record) => {
    let reqData = {
      ...record,
      is_active: false,
    };
    let successFn = async function (result) {
      await getDynamicData();
    };
    let errorFn = function (error) {};
    postAPI(DYNAMIC_URL, reqData, successFn, errorFn);
  };

  const getUserData = () => {
        let apiParams = {
          pagination: false,
        };

        let successFn = function (result) {
          setUserData(result.user);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
        };
        getAPI(USER_CLONE, successFn, errorFn, apiParams);
  };

  const getDynamicData = (page=1) => {
    setLoading(true);
    let apiParams = {
      page,
      page_size: DEFAULT_PAGE_SIZE,
      ...filterKeys,
    };

    let successFn = function (result) {
      const {next, current, count} = result;
      setDynamicData(result.results);
      setCount(count);
      setCurrentPage(current);
      setnextPage(next);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (text, record, index) => index + 1,
      width: 50,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
        <span>
          <Popconfirm
            title="Are you sure to Delete this?"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              <DeleteOutlined/>
            </Button>
          </Popconfirm>
        </span>
        ),
    }
  ];

  return (
    <div style={{ margin: '20px' }}>
      <Spin spinning={loading}>
      <div className='container-dropdown' style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', overflow: 'auto', maxHeight: '80vh'}}>
        <div className='filter-container' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 style={{marginBottom: '20px' }}>Dynamic Setting List</h2>
          <div>
            <Input style={{ width: 250, marginRight: '10px' }} placeholder="Name" value={filterKeys?.name__icontains} onChange={(e) => filterData('name__icontains', e.target.value)}/>
            <Input style={{ width: 250, marginRight: '10px' }} placeholder="Value" value={filterKeys?.value__icontains} onChange={(e) => filterData('value__icontains', e.target.value)}/>
            <Button type="primary" onClick={handleReset} icon={<ReloadOutlined />} style={{ marginLeft: '10px' }}></Button>
          </div>
          </div>
          <Table
            dataSource={dynamicData}
            columns={UserData?.is_superuser ? columns : columns.filter(col => col.key !== 'action')}
            pagination={false}
            bordered
            size="middle"
            scroll={{ x: 'max-content' }}
          />
          <CustomPagination
            nextPage={nextPage}
            count={count}
            currentPage={currentPage}
            loadData={getDynamicData}
          />
        </div>
      </Spin>
    </div>
  );
};

export default ViewDynamicSetting;
