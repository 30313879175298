import React, { useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { PROJECT_URL } from "../../../constants/api";
import {putAPI, getAPI} from '../../../utils/apiRequest/index';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import { Form, Select, Input, Button, Row, Col, Card, Checkbox } from 'antd';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UpdateProject = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    id:"",
    label:""
  });

  const [form] = Form.useForm();
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [descriptionVal, setDescriptionVal] = useState("");
  const location = useLocation();

  const onEditorStateChange = (name, content) => {
    setDescriptionVal(content);
  };

  useEffect(() => {
    if (!loading) {
      setDescriptionVal("");
    }
  }, [loading]);

  const handleProjectSelect = (ProjectValue, is_update=false) => {
    let selectedProject={};
        if(is_update){
            selectedProject = projectList.filter((project) => project.id === Number(ProjectValue))[0];
        }
        else{
            selectedProject = ProjectValue;
        }
    if(selectedProject){
    form.setFieldsValue({
      id: selectedProject?.id,
      name: selectedProject?.name,
      description: selectedProject?.description,
      label: selectedProject?.label,
    });
    setFormData(selectedProject);
  }
  };

  useEffect(() => {
    getProject();
    if(location?.state && location?.state?.editData){
            handleProjectSelect(location?.state?.editData);
    }
  },[]);

  const getProject = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
    };

    let successFn = function (result) {
      setProjectList(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(PROJECT_URL, successFn, errorFn, apiParams);
  };


  const handleSubmit = async (values) => {
    const { description, label} = values;
    values.id=formData.id;
    values.is_active ? (values.is_active = false) : delete values.is_active;
    if(description==null || label==null){
        emptyFn();
    }
    else{
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'Project Updated Successfully.');
        form.resetFields();

        setLoading(false);
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
        setLoading(false);
    };

    putAPI(PROJECT_URL, values, successFn, errorFn);
}
  };

  return (
    <Card style={{padding:24}}>
    <h2 style={{marginBottom: '40px' }}>Update Project</h2>
    <Form onFinish={handleSubmit} labelCol={{ span: 3 }} wrapperCol={{ span: 10 }} form={form}>
    <Row>
    <Col span={24}>
      <Form.Item label="Project Name" name="name" rules={[{required: true}]}>
      <Select placeholder={"Select Project"} onChange={(value) => handleProjectSelect(value, true)} optionFilterProp={"children"} showSearch={true}>
          {projectList.map((project) => (
            <Select.Option key={project.name} value={project.id}>
              {project.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label={"Label"} name={"label"} rules={[{required: true}]}>
      <Input placeholder="Label"/>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label="Description" name={"description"} rules={[{required: true}]}>
          <ReactQuill
              value={descriptionVal}
              onChange={(content) =>
                  onEditorStateChange("description", content)
              }
              modules={{
                  toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{header: 1}, {header: 2}],
                      [{list: "ordered"}, {list: "bullet"}],
                      [{script: "sub"}, {script: "super"}],
                      [{indent: "-1"}, {indent: "+1"}],
                      [{direction: "rtl"}],
                      [{size: ["small", false, "large", "huge"]}],
                      [{header: [1, 2, 3, 4, 5, 6, false]}],
                      [{color: []}, {background: []}],
                      [{font: []}],
                      [{align: []}],
                      ["clean"],
                  ],
              }}
              className="default-rich-text-editor my-editor"
          />
      </Form.Item>
    </Col>
    <Col span={24}>
        <Form.Item name="is_active" valuePropName="checked" initialValue={false} noStyle>
            <Checkbox>Delete Project</Checkbox>
        </Form.Item>
    </Col>
    </Row>
    <Col span={24} style={{textAlign:"center"}}>
    <Form.Item>
    <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
      {loading ? 'Updating...' : 'Update'}
    </Button>
  </Form.Item>
</Col>
</Form>
</Card>
  );
};
export default UpdateProject;
