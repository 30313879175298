import React, { useState, useEffect} from "react";
import "../../../assets/css/Custom.css";
import { EMPLOYEE_URL, DYNAMIC_URL, TASK_URL, PROJECT_URL } from "../../../constants/api";
import {postAPI, getAPI} from '../../../utils/apiRequest/index';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE, LABEL, PRIORITY, TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import { Form, Select, Input, Button, DatePicker, Row, Col, Card, Modal, Space, Divider } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import BulkTaskUpload from "./BulkTaskUpload";
import UploadFile from '../../common/uploadFile';

const AddTask = () => {
    const [formData, setFormData] = useState({
      project: "",
      name: "",
      description: "",
      label: "",
      type: "",
      priority: "",
      due_date: null,
      reporter: "",
      assigned_to: "",
      document: "",
    });

    const [form] = Form.useForm();
    const [projectList, setProjectList] = useState([]);
    const [labelList, setLabel] = useState([]);
    const [typeList, setType] = useState([]);
    const [priorityList, setPriority] = useState([]);
    const [employeeList, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [descriptionVal, setDescriptionVal] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDocModalOpen, setIsDocModalOpen] = useState(false);
    const [uploadFileStateMultiple, setUploadFileStateMultiple] = useState({});

    const onEditorStateChange = (name, content) => {
    setDescriptionVal(content);
  };

useEffect(() => {
  if (!loading) {
    setDescriptionVal("");
  }
}, [loading]);

    const handleInputChange = (type, values) => {
      setFormData((formData)=>{
        return{...formData, [type]:values ? values : null}
      });
    };

    useEffect(() => {
        getProject();
        getLabel();
        getType();
        getPriority();
        getEmployee();
      }, []);

      const getProject = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          request_size: "low"
        };

        let successFn = function (result) {
          setProjectList(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(PROJECT_URL, successFn, errorFn, apiParams);
      };

      const getLabel = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          name:LABEL,
        };

        let successFn = function (result) {
          setLabel(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
      };

      const getPriority = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          name:PRIORITY,
        };

        let successFn = function (result) {
          setPriority(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
      };

      const getType = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          name:TYPE,
        };

        let successFn = function (result) {
          setType(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
      };

      const getEmployee = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          request_size: "low"
        };

        let successFn = function (result) {
          setEmployee(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(EMPLOYEE_URL, successFn, errorFn, apiParams);
      };


      const handleSubmit = async (values) => {
        const { project, name, label, type, priority, reporter, assigned_to, due_date } = values;
        const modifiedDueDate = due_date ? dayjs(due_date).format("YYYY-MM-DD") : undefined;
        values.due_date = modifiedDueDate;
        values.description = descriptionVal;
          let documents = [];
          if (uploadFileStateMultiple['documents']) {
              Object.keys(uploadFileStateMultiple['documents']).map((file, index) => {
                  documents.push({file: uploadFileStateMultiple['documents'][file]?.is_Upload ? uploadFileStateMultiple['documents'][file].file_name : uploadFileStateMultiple['documents'][file].id})
              })
          }
          values.documents = documents

        if(name==null || project==null || label==null || type==null || priority==null || reporter==null || assigned_to==null){
            emptyFn();
        }
        else{
        setLoading(true);
        let successFn = function (result) {
            displayMessage(SUCCESS_MSG_TYPE, 'Task Added Successfully.');
            setLoading(false);
            form.resetFields();
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
            Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
            setLoading(false);
        };

        postAPI(TASK_URL, values, successFn, errorFn);
    }
};
const toggleModal = (value) => {
    setIsModalOpen(value)
}

const toggleDocModal = (value) => {
    setIsDocModalOpen(value)
}

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};

return (
<Card style={{padding:24}}>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2 style={{marginBottom: '40px'}}>Add Task</h2>
        <Button style={{marginTop: '-30px'}} size={'large'} disabled={false} onClick={() => toggleModal(true)}>
            <PlusOutlined/> Upload from Excel
        </Button>
    </div>
    <Modal open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)} width={700}>
        <BulkTaskUpload setIsModalOpen={setIsModalOpen}/>
    </Modal>
    <Form form={form} onFinish={handleSubmit} labelCol={{
        span: 4,
    }}
          wrapperCol={{
              span: 20,
        }}>

      <Row>
          <Col xl={12} lg={12} md={24} sm={24}>
            <Form.Item label={"Project"} name={"project"} rules={[{required: true}]}>
              <Select placeholder={"Project"} onChange={(value) => handleInputChange("project", value)} optionFilterProp={"children"} showSearch={true}>
                {projectList.map((project) => (
                  <Select.Option label={project.id} value={project.id}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
          <Form.Item label={"Label"} name={"label"} rules={[{required: true}]}>
            <Select placeholder={"Label"} onChange={(value) => handleInputChange("label", value)} optionFilterProp={"children"} showSearch={true}>
              {labelList.map((label) => (
                <Select.Option key={label.id} value={label.id} label={label.value}>
                  {label.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
          <Form.Item label={"Issue Type"} name={"type"} rules={[{required: true}]}>
            <Select placeholder={"Issue Type"} onChange={(value) => handleInputChange("type", value)} optionFilterProp={"children"} showSearch={true}>
              {typeList.map((type) => (
                <Select.Option label={type.id} value={type.id}>
                  {type.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
          <Form.Item label={"Priority"} name={"priority"} rules={[{required: true}]}>
            <Select placeholder={"Priority"} onChange={(value) => handleInputChange("priority", value)} optionFilterProp={"children"} showSearch={true}>
              {priorityList.map((priority) => (
                <Select.Option label={priority.id} value={priority.id}>
                  {priority.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
            <Form.Item label={"Assigned To"} name={"assigned_to"} rules={[{required: true}]}>
                <Select placeholder={"Assigned To"} onChange={(value) => handleInputChange("assigned_to", value)} optionFilterProp={"children"} showSearch={true}>
                    {employeeList.map((assigned_to) => (
                        <Select.Option label={assigned_to.id} value={assigned_to.id}>
                            {assigned_to.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
            <Form.Item label={"Reporter"} name={"reporter"}>
                <Select placeholder={"Reporter"} onChange={(value) => handleInputChange("reporter", value)} optionFilterProp={"children"} showSearch={true}>
                    {employeeList.map((reporter) => (
                        <Select.Option label={reporter.id} value={reporter.id}>
                            {reporter.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
          <Form.Item label="Title" name="name" rules={[{required: true}]}>
            <Input placeholder="Title"/>
          </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
            <Form.Item label="Due Date" name={"due_date"}>
                <DatePicker
                    placeholder="Due Date"
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    disabledDate={(current) => current && current < new Date().setHours(0, 0, 0, 0)}
                />
                {/* <Input/> */}
            </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
        <Form.Item label="Description" name={"description"}>
          <ReactQuill
                value={descriptionVal}
                onChange={(content) =>
                    onEditorStateChange("description", content)
                }
                modules={{
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{header: 1}, {header: 2}],
                        [{list: "ordered"}, {list: "bullet"}],
                        [{script: "sub"}, {script: "super"}],
                        [{indent: "-1"}, {indent: "+1"}],
                        [{direction: "rtl"}],
                        [{size: ["small", false, "large", "huge"]}],
                        [{header: [1, 2, 3, 4, 5, 6, false]}],
                        [{color: []}, {background: []}],
                        [{font: []}],
                        [{align: []}],
                        ["clean"],
                    ],
                }}
            />
            </Form.Item>
        </Col>
        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
            <Form.Item label="Documents" name="document" >
                <Button style={{marginTop: '-30px'}} size={'large'} disabled={false} onClick={() => toggleDocModal(true)}>
                    <PlusOutlined/> Upload
                </Button>
            </Form.Item>
        </Col>

        <Col span={24} style={{textAlign:"center"}}>
        <Form.Item>
        <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
          {loading ? 'Adding...' : 'Add'}
        </Button>
      </Form.Item>
        </Col>
      </Row>
    </Form>
        <Modal open={isDocModalOpen} footer={null} onCancel={() => setIsDocModalOpen(false)} width={700}>
            <Form form={form}>
            <Form.List name={"documents"}>
                {(fields, { add, remove }) => (
                    <Form.Item label={"Documents"}>
                        {fields.map((field, index) => (
                            <Space
                                key={index}
                                style={{ alignItems: "baseline", width: '100%' }}
                                size={70}
                            >
                                <Row>
                                    <Col span={23}>
                                        <Form.Item>
                                            <UploadFile
                                                isMultiple={true}
                                                keyName={'documents'}
                                                key={`Upload_document${index}`}
                                                labelCol={{ span: 10 }}
                                                {...field}
                                                name={[field.name, "documents"]}
                                                extra={uploadFileStateMultiple['documents'] && uploadFileStateMultiple['documents'][`${field.name},documents`] && uploadFileStateMultiple['documents'][`${field.name},documents`]?.is_Upload ? uploadFileStateMultiple['documents'][`${field.name},documents`]?.file_name : null}
                                                label={`Document ${index + 1}`}
                                                setState={setUploadFileStateMultiple}
                                                setLoading={setLoading}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1} style={{ paddingLeft: "10px" }}>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Col>
                                </Row>
                                <Divider />
                            </Space>
                        ))}
                        <Form.Item style={{ paddingTop: "30px" }}>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                            >
                                Add Document
                            </Button>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Space>
                                <Button type={'primary'} className={'theme-color'}  onClick={() => setIsDocModalOpen(false)}>Save</Button>
                            </Space>
                        </Form.Item>
                    </Form.Item>
                )}
            </Form.List>
            </Form>
        </Modal>
    </Card>
  );
};
export default AddTask;
