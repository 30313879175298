import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { TASK_URL, PROJECT_URL, EMPLOYEE_URL, DYNAMIC_URL } from "../../../constants/api";
import {getAPI, putAPI} from '../../../utils/apiRequest/index';
import {displayMessage, emptyFn, handleErrorResponse} from "../../../utils/common";
import {
    getPriorityColor,
    getStatusColor,
    LABEL,
    PERIOD_CHOICES,
    PRIORITY,
    STATUS, SUCCESS_MSG_TYPE,
    TYPE
} from '../../../constants/dataKeys';
import {
    Table,
    Spin,
    Select,
    Button,
    Modal,
    Row,
    Col,
    Typography,
    DatePicker,
    Card,
    Tag, Form, Divider, Empty, Checkbox, Dropdown, Space
} from 'antd';
import { ReloadOutlined, TeamOutlined, UserOutlined, CalendarOutlined, MessageOutlined, GoldOutlined, ClockCircleOutlined, DownOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const ViewKanbanBoard = () => {
    const [TaskData, setTaskData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterKeys, setFilterKeys] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [statusList, setStatus] = useState([]);
    const [priorityList, setPriority] = useState([]);
    const [labelList, setLabel] = useState([]);
    const [typeList, setType] = useState([]);
    const [assigned_toList, setAssignedTo] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').startOf('day'));
    const [endDate, setEndDate] = useState(dayjs());
    const [selectedProject, setSelectedProject] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState([]);
    const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
    const [form] = Form.useForm();

    const updateInitialValues = () => {
        form.setFieldsValue({
            status: selectedRowData?.status_data?.id,
        });
    };

    // Use useEffect to trigger the update when selectedRowData changes
    useEffect(() => {
        updateInitialValues();
    }, [selectedRowData]);

    useEffect(() => {
        getProject();
        getStatus();
        getPriority();
        getType();
        getLabel();
        getAssignedTo();
    }, []);

    const onChangeDateRange = (dates, dateStrings) => {
        setStartDate(dates && dates[0]);
        setEndDate(dates && dates[1]);
        setFilterKeys((prevFilterKeys) => ({
            ...prevFilterKeys,
            period: null,
        }));
    };

    const handleReset = () => {
        setFilterKeys({});
        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
        setEndDate(dayjs());
        setSelectedProject([]);
        setSelectedPriority([]);
        setSelectedType([]);
        setSelectedLabel([]);
        setSelectedAssignedTo([]);
    };

    useEffect(() => {
        getTaskData();
    }, [filterKeys, startDate, endDate]);

    const filterData = (type, value) => {
        setFilterKeys((filterKeys) => {
            return {...filterKeys, [type]: value ? value : undefined};
        });
    };

    const filterMultipleData = (type, value) => {
        setFilterKeys((filterKeys) => {
            const joinedValue = value ? value.join(',') : undefined;
            return { ...filterKeys, [type]: joinedValue };
        });
    };

    const getProject = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            request_size: "low"
        };

        let successFn = function (result) {
            setProjectList(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(PROJECT_URL, successFn, errorFn, apiParams);
    };

    const getStatus = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            name:STATUS,
        };

        let successFn = function (result) {
            setStatus(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
    };

    const getPriority = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            name:PRIORITY,
        };

        let successFn = function (result) {
            setPriority(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
    };

    const getLabel = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            name:LABEL,
        };

        let successFn = function (result) {
            setLabel(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
    };

    const getAssignedTo = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            request_size: "low"
        };

        let successFn = function (result) {
            setAssignedTo(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(EMPLOYEE_URL, successFn, errorFn, apiParams);
    };

    const getType = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            name:TYPE,
        };

        let successFn = function (result) {
            setType(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
    };

    const getTaskData = (page = 1, id = null, isSpecific = false) => {
        setLoading(true);

        let apiParams = {
            pagination: false,
            ...filterKeys,
        };

        if (isSpecific) {
            apiParams.is_specific = true;
            apiParams.id = id;
        } else {
            apiParams.is_report = true;
            apiParams.request_size = "medium";
        }

        let successFn = function (result) {
            if (isSpecific) {
                setSelectedRowData(result[0]);
            } else {
                setTaskData(result);
            }
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };

        getAPI(TASK_URL, successFn, errorFn, apiParams);
    };

    const handleStatusChange = async (values) => {
        const statusData = {
            "status": values,
            "id": selectedRowData?.id,
            "project": selectedRowData?.project,
            "name": selectedRowData?.name
        }
        if (statusData.status == null) {
            emptyFn();
        } else {
            setLoading(true);
            let successFn = function (result) {
                const message = 'Task Status Updated!';
                displayMessage(SUCCESS_MSG_TYPE, message);
                setLoading(false);
                setIsModalVisible(false);
                getTaskData();
            };
            let errorFn = function (error) {
                handleErrorResponse(error);
                setLoading(false);
            };
            putAPI(TASK_URL, statusData, successFn, errorFn);
        }
    };

    const renderStatusColumn = (record) => {
        let tasks = [];
        tasks.push(record);
        return (
            tasks.map((task) => (
                <React.Fragment>
                    <Card
                        title={`Task ID: ${task.task_id}`}
                        style={{boxSizing: 'border-box', backgroundColor: '#f0f0f0'}}
                        onClick={() => handleRowClick(record)}
                        headStyle={{marginTop: '-10px', marginBottom: '-10px', paddingLeft: '10px'}}
                    >
                        <div style={{
                            marginTop: '-20px',
                            marginLeft: '-10px',
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontWeight: "bold",
                            fontSize: "0.8rem"
                        }}>{task.name}</div>
                        <div style={{marginLeft: '-10px'}}>{task?.priority_data?.value}</div>
                        <div style={{marginBottom: '-20px', marginLeft: '-10px'}}>{task?.assigned_to_data?.name}</div>
                    </Card>
                    <Divider/>
                </React.Fragment>
            ))
        );
    };


    const [columns, setColumns] = useState([
        {
            title: <Tag color={getStatusColor('Open')}>Open</Tag>,
            dataIndex: 'open',
            key: 'open',
            ellipsis: true,
            render: (open, record) => <span>{ record?.Open ? record?.Open.map((x) => renderStatusColumn(x)) : null}</span>,
            width: 150,
            hidden: false,
        },
        {
            title: <Tag color={getStatusColor('To Discuss')}>To Discuss</Tag>,
            dataIndex: 'to_discuss',
            key: 'to_discuss',
            ellipsis: true,
            render: (to_discuss, record) => <span>{ record?.To_Discuss ? record?.To_Discuss.map((x) => renderStatusColumn(x)) : null}</span>,
            width: 150,
            hidden: false,
        },
        {
            title: <Tag color={getStatusColor('Pending')}>Pending</Tag>,
            dataIndex: 'pending',
            key: 'pending',
            ellipsis: true,
            render: (pending, record) => <span>{record?.Pending ? record?.Pending.map((x) => renderStatusColumn(x)) :  null}</span>,
            width: 150,
            hidden: false,
        },
        {
            title: <Tag color={getStatusColor('In Progress')}>In Progress</Tag>,
            dataIndex: 'in_progress',
            key: 'in_progress',
            ellipsis: true,
            render: (in_progress, record) => <span>{record?.In_Progress ? record?.In_Progress.map((x) => renderStatusColumn(x)) : null}</span>,
            width: 150,
            hidden: false,
        },
        {
            title: <Tag color={getStatusColor('Blocked')}>Blocked</Tag>,
            dataIndex: 'blocked',
            key: 'blocked',
            ellipsis: true,
            render: (blocked, record) => <span>{record?.Blocked ? record?.Blocked.map((x) => renderStatusColumn(x)) : null}</span>,
            width: 150,
            hidden: false,
        },
        {
            title: <Tag color={getStatusColor('Over Due')}>Over Due</Tag>,
            dataIndex: 'over_due',
            key: 'over_due',
            ellipsis: true,
            render: (over_due, record) => <span>{record?.Over_Due ? record?.Over_Due.map((x) => renderStatusColumn(x)) : null}</span>,
            width: 150,
            hidden: false,
        },
        {
            title: <Tag color={getStatusColor('Completed')}>Completed</Tag>,
            dataIndex: 'completed',
            key: 'completed',
            ellipsis: true,
            render: (completed, record) => <span>{record?.Completed ? record?.Completed.map((x) => renderStatusColumn(x)) : null}</span>,
            width: 150,
            hidden: true,
        },
        {
            title: <Tag color={getStatusColor('Ready for Testing')}>Ready for Testing</Tag>,
            dataIndex: 'ready_for_testing',
            key: 'ready_for_testing',
            ellipsis: true,
            render: (ready_for_testing, record) => <span>{ record?.Ready_for_Testing ? record?.Ready_for_Testing.map((x) =>
                renderStatusColumn(x)) : null}</span>,
            width: 150,
            hidden: true,
        },
        {
            title: <Tag color={getStatusColor('Closed')}>Closed</Tag>,
            dataIndex: 'closed',
            key: 'closed',
            ellipsis: true,
            render: (closed, record) => <span>{record?.Closed ? record?.Closed.map((x) => renderStatusColumn(x)) : null}</span>,
            width: 150,
            hidden: true,
        },
    ])
    const [isRender, setIsRender] = useState(false);

    const handleRowClick = (record) => {
        getTaskData(1, record?.id, true)
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const items = [
        {
            label: <Checkbox>Completed</Checkbox>,
            key: 'completed',
        },
        {
            label: <Checkbox>Ready for Testing</Checkbox>,
            key: 'ready_for_testing',
        },
        {
            label: <Checkbox>Closed</Checkbox>,
            key: 'closed',
        },
    ]
    const handleMenuClick = (event) => {
        let {key, domEvent} = event
        let newArr = columns
        let objIndex = newArr.findIndex((obj => obj.key == key));
        newArr[objIndex].hidden = !domEvent.target.checked
        setColumns(newArr)
        setIsRender(!isRender)
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <div style={{ margin: '20px' }}>
            <Spin spinning={loading}>
                <div className='container-task' style={{
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    overflow: 'auto',
                    maxHeight: '80vh'
                }}>
                    <div className='filter-container' style={{
                        marginBottom: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <h2 style={{marginBottom: '20px'}}>Kanban Board</h2>
                        <div>
                            <RangePicker
                                style={{marginRight: '10px', width: 180,}}
                                allowClear={false}
                                value={[startDate, endDate]}
                                className="range-picker"
                                format={"DD/MM/YYYY"}
                                onChange={onChangeDateRange}
                            />
                            <Select
                                style={{width: 120, marginRight: '10px'}}
                                onChange={(e) => {
                                    filterData('period', e);
                                    if (e === undefined) {
                                        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
                                        setEndDate(dayjs());
                                    } else {
                                        setStartDate(); setEndDate();
                                    }
                                }}
                                placeholder="Period"
                                value={filterKeys?.period}
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                            >
                                {PERIOD_CHOICES?.map((option) => (
                                    <Select.Option key={option.key} value={option.key} label={option.name}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                style={{width: 100, marginRight: '10px'}}
                                value={selectedProject}
                                onChange={(value) => {
                                    setSelectedProject(value);
                                    filterMultipleData('project', value);
                                }}
                                placeholder="Project"
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                                mode={"multiple"}
                            >
                                {projectList?.map((option) => (
                                    <Select.Option
                                        label={option.name}
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                style={{width: 100, marginRight: '10px'}}
                                value={selectedPriority}
                                onChange={(value) => {
                                    setSelectedPriority(value);
                                    filterMultipleData('priority', value);
                                }}
                                placeholder="Priority"
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                                mode={"multiple"}
                            >
                                {priorityList?.map((option) => (
                                    <Select.Option
                                        label={option.value}
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.value}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                style={{width: 100, marginRight: '10px'}}
                                value={selectedType}
                                onChange={(value) => {
                                    setSelectedType(value);
                                    filterMultipleData('type', value);
                                }}
                                placeholder="Type"
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                                mode={"multiple"}
                            >
                                {typeList?.map((option) => (
                                    <Select.Option
                                        label={option.value}
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.value}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                style={{width: 100, marginRight: '10px'}}
                                value={selectedLabel}
                                onChange={(value) => {
                                    setSelectedLabel(value);
                                    filterMultipleData('label', value);
                                }}
                                placeholder="Label"
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                                mode={"multiple"}
                            >
                                {labelList?.map((option) => (
                                    <Select.Option
                                        label={option.value}
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.value}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                style={{width: 110, marginRight: '10px'}}
                                value={selectedAssignedTo}
                                onChange={(value) => {
                                    setSelectedAssignedTo(value);
                                    filterMultipleData('assigned_to', value);
                                }}
                                placeholder="Assigned To"
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                                mode={"multiple"}
                            >
                                {assigned_toList?.map((option) => (
                                    <Select.Option
                                        label={option.name}
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Dropdown style={{width: 110, marginRight: '10px'}} allowClear={true} menu={menuProps}>
                                <Button size={'small'}>
                                    <Space>
                                        Columns
                                        <DownOutlined/>
                                    </Space>
                                </Button>
                            </Dropdown>
                            <Button type="primary" onClick={handleReset} icon={<ReloadOutlined/>}
                                    style={{marginLeft: '10px'}}></Button>
                        </div>
                    </div>
                    <Table
                        className={"kanban-table"}
                        dataSource={TaskData}
                        columns={columns.filter(item => !item.hidden)}
                        pagination={false}
                        bordered
                        size="middle"
                        scroll={{x: 'max-content'}}
                    />
                </div>
                <Modal
                    title={
                        <div style={{ fontSize: '1.5rem', fontWeight: 'bold', paddingLeft: 10 }}>
                            {selectedRowData?.task_id + ' : ' + selectedRowData?.name}
                        </div>
                    }
                    open={isModalVisible}
                    onCancel={handleModalCancel}
                    width={"40%"}
                    footer={[
                        <Button key="close" onClick={handleModalCancel}>
                            Close
                        </Button>,
                    ]}
                >
                    {selectedRowData && (
                        <Row style={{padding: "8px 8px"}}>
                            <Col
                                span={12}
                                style={{cursor: "pointer"}}
                            >
                                <RenderTaskDetail
                                    label={"Project"}
                                    value={
                                        selectedRowData?.project_data?.name
                                            ? selectedRowData.project_data?.name
                                            : "--"
                                    }
                                    icon={<GoldOutlined/>}
                                />
                                <RenderTaskDetail
                                    label={"Time Spent"}
                                    value={
                                        selectedRowData?.total_time_spent
                                            ? selectedRowData?.total_time_spent
                                            : "--"
                                    }
                                    icon={<ClockCircleOutlined/>}
                                />
                            </Col>
                            <Col span={12}>
                                <Form form={form} initialValues={{status:selectedRowData?.status_data?.id}} colon={false}>
                                    <Form.Item name={"status"} label={<Typography
                                        style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem"}}>
                                        Task Status:</Typography>}>
                                        <Select
                                            style={{ width: 150, marginLeft: '10px', color: getStatusColor(selectedRowData?.status_data?.value)}}
                                            onChange={(value) => handleStatusChange(value)}
                                            placeholder="Status"
                                            optionFilterProp={"children"}
                                            showSearch={true}
                                        >
                                            {statusList?.map((option) => (
                                                <Select.Option
                                                    label={option.value}
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    <Tag style={{ fontWeight: "bold", fontSize:"1.1rem" }} color={getStatusColor(option.value)} >{option.value}</Tag>
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col
                                span={12}
                                style={{cursor: "pointer"}}
                            >
                                <RenderTaskDetail
                                    label={"Label"}
                                    value={
                                        selectedRowData?.label_data?.value
                                            ? selectedRowData.label_data?.value
                                            : "--"
                                    }
                                    icon={<CalendarOutlined/>}
                                />
                                <RenderTaskDetail
                                    label={"Type"}
                                    value={
                                        selectedRowData?.type_data?.value
                                            ? selectedRowData.type_data?.value
                                            : "--"
                                    }
                                    icon={<CalendarOutlined/>}
                                />
                            </Col>
                            <Col span={12}>
                                <Typography
                                    style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem", paddingTop: 5}}>
                                    Task Priority: <Tag style={{fontWeight: "bold", fontSize: "1.3rem"}}
                                                        color={getPriorityColor(selectedRowData?.priority_data?.value)}>{selectedRowData?.priority_data?.value ? selectedRowData?.priority_data?.value : "--"}</Tag>
                                </Typography>
                            </Col>
                            <Col
                                span={12}
                                style={{cursor: "pointer"}}
                            >
                                <RenderTaskDetail
                                    label={"Assignee"}
                                    value={
                                        selectedRowData?.assigned_to_data?.name
                                            ? selectedRowData?.assigned_to_data?.name
                                            : "--"
                                    }
                                    icon={<TeamOutlined/>}
                                    valueSize={14}
                                />
                                <RenderTaskDetail
                                    label={"Reporter"}
                                    value={
                                        selectedRowData?.reporter_data?.name
                                            ? selectedRowData.reporter_data?.name
                                            : "--"
                                    }
                                    icon={<UserOutlined/>}
                                />
                            </Col>
                            <Col span={12}>
                                <Typography style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem"}}>
                                    Due Date: <Tag style={{
                                    fontWeight: "bold",
                                    fontSize: "1.3rem"
                                }}>{selectedRowData?.due_date ? selectedRowData?.due_date : "--"}</Tag>
                                </Typography>
                                <Typography style={{fontWeight: "650", cursor: "pointer", fontSize: "1.1rem"}}>
                                    Reopen Count: <Tag style={{
                                    fontWeight: "bold",
                                    fontSize: "1.0rem"
                                }}>{selectedRowData?.reopen_count ? selectedRowData.reopen_count : "0"}</Tag>
                                </Typography>
                            </Col>
                            <Col
                                span={12}
                                style={{cursor: "pointer"}}
                            >
                                <RenderTaskDetail
                                    label={"Modified By"}
                                    value={
                                        selectedRowData?.modified_by_data?.name
                                            ? selectedRowData.modified_by_data.name
                                            : "--"
                                    }
                                    icon={<TeamOutlined/>}
                                    valueSize={14}
                                />
                            </Col>
                            <Col span={12}>
                                <Typography style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem"}}>
                                    Blocked By: <Tag style={{
                                    fontWeight: "bold",
                                    fontSize: "1.3rem"
                                }}>{selectedRowData?.blocked_by_data?.name ? selectedRowData?.blocked_by_data?.name : "--"}</Tag>
                                </Typography>
                            </Col>
                            <Col span={24}>
                                <RenderFullWidthDetail
                                    label={"Comment"}
                                    value={
                                        selectedRowData?.comment
                                            ? selectedRowData.comment
                                            : "--"
                                    }
                                    icon={<MessageOutlined/>}
                                    valueSize={14}
                                />
                            </Col>
                            <Col span={24}>
                                <Typography style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    fontWeight: '650',
                                    cursor: 'pointer'
                                }}>
                                    <span style={{marginRight: '8px'}}><MessageOutlined/></span>
                                    <span style={{
                                        fontWeight: "650",
                                        fontSize: "1.0rem",
                                        paddingLeft: 8,
                                        whiteSpace: 'nowrap'
                                    }}>Description:</span>
                                    <ReactQuill
                                        readOnly
                                        value={selectedRowData?.description ? selectedRowData.description : "--"}
                                        style={{height: 'auto', paddingLeft: 10}}
                                        className="react-quill-view"
                                        modules={{toolbar: false}}
                                    />
                                </Typography>
                            </Col>
                            <Col span={24}>
                                <RenderFullWidthDetail
                                    label={"Documents"}
                                    value={
                                        selectedRowData?.documents && selectedRowData.documents.length > 0 ? (
                                            selectedRowData.documents.map((document, index) => (
                                                <div key={index}>
                                                    <p>
                                                        <strong>{index + 1}.</strong>&nbsp;
                                                        <a href={document.file_path} target="_blank" rel="noopener noreferrer">
                                                            {document.file_label}
                                                        </a>
                                                    </p>
                                                </div>
                                            ))
                                        ) : (
                                            "--"
                                        )
                                    }
                                    icon={<CalendarOutlined />}
                                />
                            </Col>
                        </Row>
                    )}
                </Modal>
            </Spin>
        </div>
    )
        ;
};

export default ViewKanbanBoard;

function RenderTaskDetail(props) {
    return (
        <Row style={{ paddingBottom:"5px"}}>
            <Col span={props.iconSize ? props.iconSize : 2}>{props.icon}</Col>
            <Col
                span={props.labelSize ? props.labelSize : 7}
            >
                <Text style={{ fontWeight: "650", fontSize: "1.0rem" }}>
                    {props.label}:{" "}
                </Text>
            </Col>
            <Col
                className={"post-header"}
                span={props.valueSize ? props.valueSize : 15}
            >
                <Text style={{ fontSize: "1.0rem" }}>{props.value}</Text>
            </Col>
        </Row>
    );
}

function RenderFullWidthDetail(props) {
    return (
        <Row style={{ paddingBottom:"5px"}}>
            <Col span={1}>{props.icon}</Col>
            <Col span={4}>
                <Text style={{ fontWeight: "650", fontSize: "1.0rem"}}>
                    {props.label}:{" "}
                </Text>
            </Col>
            <Col span={19}>
                <Text style={{ fontSize: "1.0rem"}}>{props.value}</Text>
            </Col>
        </Row>
    );
}
