import React, { useState, useEffect} from "react";
import { PROJECT_URL} from "../../../constants/api";
import {postAPI} from '../../../utils/apiRequest/index';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn} from '../../../utils/common';
import { Form, Input, Button, Row, Col, Card } from 'antd';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const AddProject = () => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [descriptionVal, setDescriptionVal] = useState("");

  const onEditorStateChange = (name, content) => {
    setDescriptionVal(content);
};

useEffect(() => {
  if (!loading) {
    setDescriptionVal("");
  }
}, [loading]);

  const handleSubmit = async (values) => {
    values.description = descriptionVal;
    const { name, label, description} = values;
    if(name==null || description==null || label==null){
        emptyFn();
    }
    else{
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'Project Added Successfully.');
        setLoading(false);
        form.resetFields();
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
        setLoading(false);
    };

    postAPI(PROJECT_URL, values, successFn, errorFn);
}
  };

  return (
    <Card style={{padding:24}}>
    <h2 style={{marginBottom: '40px' }}>Add Project</h2>
    <Form onFinish={handleSubmit} labelCol={{ span: 3 }} wrapperCol={{ span: 10 }} form={form}>
    <Row>
    <Col xl={24} lg={24} md={24} sm={24}>
      <Form.Item label="Name" name="name" rules={[{required: true}]}>
        <Input placeholder="Name"/>
      </Form.Item>
    </Col>

    <Col xl={24} lg={24} md={24} sm={24}>
      <Form.Item label={"Label"} name={"label"} rules={[{required: true}]}>
      <Input placeholder="Label"/>
      </Form.Item>
    </Col>

    <Col xl={24} lg={24} md={24} sm={24}>
      <Form.Item label="Description" name={"description"} rules={[{required: true}]}>
          <ReactQuill
              value={descriptionVal}
              onChange={(content) =>
                  onEditorStateChange("description", content)
              }
              modules={{
                  toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{header: 1}, {header: 2}],
                      [{list: "ordered"}, {list: "bullet"}],
                      [{script: "sub"}, {script: "super"}],
                      [{indent: "-1"}, {indent: "+1"}],
                      [{direction: "rtl"}],
                      [{size: ["small", false, "large", "huge"]}],
                      [{header: [1, 2, 3, 4, 5, 6, false]}],
                      [{color: []}, {background: []}],
                      [{font: []}],
                      [{align: []}],
                      ["clean"],
                  ],
              }}
              className="default-rich-text-editor my-editor"
          />
      </Form.Item>
    </Col>

    </Row>
    <Col span={24} style={{textAlign:"center"}}>
    <Form.Item>
    <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
      {loading ? 'Adding...' : 'Add'}
    </Button>
  </Form.Item>
</Col>
</Form>
</Card>
  );
};
export default AddProject;
