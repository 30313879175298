import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { PROJECT_URL, USER_CLONE } from "../../../constants/api";
import {getAPI, postAPI} from '../../../utils/apiRequest/index';
import { handleErrorResponse } from "../../../utils/common";
import { Table, Spin, Button, DatePicker, Input, Popconfirm, Divider} from 'antd';
import { ReloadOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomPagination from "../../common/CustomPagination";

const { RangePicker } = DatePicker;

const ViewProject = () => {
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterKeys, setFilterKeys] = useState({});
  const [UserData, setUserData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(dayjs());
  const [count, setCount] = useState(1);
  const [nextPage, setnextPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const onChangeDateRange = (dates, dateStrings) => {
    setStartDate(dates && dates[0]);
    setEndDate(dates && dates[1]);
  };

  const handleReset = () => {
    setFilterKeys({});
    setStartDate();
    setEndDate(dayjs());
  };

  const filterData = (type, value) => {
    setFilterKeys((filterKeys) => {
        return {...filterKeys, [type]: value ? value : undefined};
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    getProjectData();
  }, [filterKeys, startDate, endDate]);

  const onDelete = (record) => {
    let reqData = {
      ...record,
      is_active: false,
    };
    let successFn = async function (result) {
      await getProjectData();
    };
    let errorFn = function (error) {};
    postAPI(PROJECT_URL, reqData, successFn, errorFn);
  };

  const getUserData = () => {
        let apiParams = {
          pagination: false,
        };

        let successFn = function (result) {
          setUserData(result.user);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(USER_CLONE, successFn, errorFn, apiParams);
  };

  const getProjectData = (page=1) => {
    setLoading(true);
    let apiParams = {
      page,
      page_size: 8,
      start_date: startDate && dayjs(startDate).format("YYYY-MM-DD"),
      end_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
      ...filterKeys,
    };

    let successFn = function (result) {
      const {next, current, count} = result;
      setProjectData(result.results);
      setCount(count);
      setCurrentPage(current);
      setnextPage(next);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(PROJECT_URL, successFn, errorFn, apiParams);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (text, record, index) => index + 1,
      width: 50,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      render: (text) => (
          <ReactQuill
            readOnly
            value={text}
            style={{ height: 'auto', padding: '0' }}
            className="react-quill-view"
            modules={{
              toolbar: false,
            }}
          />
      ),
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
        <span>
           <Link to="/dashboard/updateproject/" state={{editData:record}} >
              <EditOutlined/>
           </Link>
           <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to Delete this?"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              <DeleteOutlined/>
            </Button>
          </Popconfirm>
        </span>
        ),
      }
  ];

  return (
    <div>
      <Spin spinning={loading}>
      <div className='container-project' style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', overflow: 'auto', maxHeight: '80vh'}}>
      <div className='filter-container' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 style={{marginBottom: '20px' }}>Project List</h2>
          <div>
            <RangePicker
              style={{marginRight: '10px'}}
              allowClear={false}
              value={[startDate, endDate]}
              className="range-picker"
              format={"DD/MM/YYYY"}
              onChange={onChangeDateRange}
            />
            <Input style={{ width: 150, marginRight: '10px' }} placeholder="Name" value={filterKeys?.name__icontains} onChange={(e) => filterData('name__icontains', e.target.value)}/>
            <Input style={{ width: 150, marginRight: '10px' }} placeholder="Label" value={filterKeys?.label__icontains} onChange={(e) => filterData('label__icontains', e.target.value)}/>
            <Button type="primary" onClick={handleReset} icon={<ReloadOutlined />} style={{ marginLeft: '10px' }}></Button>
          </div>
          </div>
          <Table
            dataSource={projectData}
            columns={UserData?.is_superuser ? columns : columns.filter(col => col.key !== 'action')}
            pagination={false}
            bordered
            size="middle"
            scroll={{ x: 'max-content' }}
          />
          <CustomPagination
            nextPage={nextPage}
            count={count}
            currentPage={currentPage}
            loadData={getProjectData}
            pageSize={8}
          />
        </div>
      </Spin>
    </div>
  );
};

export default ViewProject;
