import {Button, Form, Space, Upload} from "antd";
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import React, { useState} from "react";
import { postAPI, putOuterAPI } from "../../../utils/apiRequest";
import {GET_FILE_UPLOAD_SIGNATURE} from "../../../constants/api";
import {_get} from "../../../utils/lodashUtils";
import { displayMessage } from "../../../utils/common";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../../../constants/dataKeys";


let s3SignatureUrl, s3FileUploadType, s3SignatureFileName;
const stringTruncate = (string, length, end = "...") => {
    return string?.length < length ? string : string?.substring(0, length) + end
}

const UploadFile = (props) => {
    const [fileList, setFileList] = useState([])

    const handleFileUpload = info => {
        let fileList = [...info.fileList];
        props?.setLoading(true)
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
            displayMessage(SUCCESS_MSG_TYPE, `${info.file.name} file uploaded successfully`);
            props.setLoading(false)
        } else if (info.file.status === "error") {
            displayMessage(ERROR_MSG_TYPE, `${info.file.name} file upload failed.`);
            props.setLoading(false)
        }
        let updatedFileList = fileList.map(file => {
            file.url = _get(file, "xhr.responseURL", "").split("?")[0];
            file.name = file.name.slice(0, 22) + (file.name.length > 22 ? "..." : "");
            return file;
        });
        setFileList(updatedFileList)
    };
    const customRequest = (customProps) => {
        uploadPresentURl(customProps,customProps?.file)
    }

    const fileUploadProps = {
        maxCount: 1,
        action: s3SignatureUrl,
        method: "put",
        onChange: handleFileUpload,
        customRequest:customRequest,
        headers: {
            "Content-Type": s3FileUploadType
        },
    };

    const uploadPresentURl=(customProps,file)=>{
        let successFn = function (data) {
            s3SignatureUrl = data.url;
            s3SignatureFileName = data.file_name;
            s3FileUploadType = file.type;
            uploadFileS3(customProps)
        };
        let errorFn = function () {
        };
        postAPI(
            GET_FILE_UPLOAD_SIGNATURE,
            {file: file.name, file_type: file.type},
            successFn,
            errorFn
        );
    }

    const uploadFileS3=(customProps)=>{
        let successFn = function (data) {
            customProps.onSuccess();
            if (props.isMultiple){
                props.setState((prevState) => ({
                    ...prevState,
                    [props.keyName]:prevState[props.keyName]?{...prevState[props.keyName],[props.name]: {
                            is_Upload: true,
                            file_name: s3SignatureFileName
                        }}:{[props.name]:{is_Upload:true,file_name:s3SignatureFileName}},

                }));
            }else {
                props.setState((prevState) => ({
                    ...prevState,
                    [props.name]: {is_Upload:true,file_name:s3SignatureFileName}
                }));
            }
        };
        let errorFn = function (error) {
        }
        putOuterAPI(
            s3SignatureUrl,
            customProps.file,
            successFn,
            errorFn,
            {
                "Content-Type": customProps.file.type
            },
        );
    }

    const removeUploadFile=(key)=>{
        if (props.isMultiple){
            props.setState((prevState)=>{
                const temState = { ...prevState };
                delete temState[props.keyName][key]
                return temState;
            })
        }else {
            props.setState((prevState)=>{
                const temState = { ...prevState };
                temState[key] = undefined;
                return temState;
            })
            if(props.setBulkUploadErrors){
                props.setBulkUploadErrors([])
            }
        }
    }
    return (
        <Form.Item {...props} style={{margin:0, padding:0}}
                   extra={props.extra?<Space>
                       <a href={'#'}>{stringTruncate(props.extra, 18)}</a>
                       <DeleteOutlined style={{color:'red'}} onClick={()=>removeUploadFile(props.name)} /></Space>:null}>
            <Upload {...fileUploadProps}
                    className={"center my-upload"}
                    style={{display: "block", margin: "auto"}}
            >
                <Button>
                    <UploadOutlined/> Click to Upload
                </Button>

            </Upload>
        </Form.Item>
    )
}
export default UploadFile