import dayjs from 'dayjs';
import { Card, Col, DatePicker, Row, Typography, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { handleErrorResponse } from "../../../utils/common";
import { TASK_REPORT_URL } from "../../../constants/api";
import {getAPI} from '../../../utils/apiRequest/index';

const { RangePicker } = DatePicker;
const { Text } = Typography;
const TaskStatus = () => {

  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);

  const onChangeDateRange = (dates, dateStrings) => {
    setStartDate(dates && dates[0]);
    setEndDate(dates && dates[1]);
  };

  const handleReset = () => {
    setStartDate(dayjs().subtract(7, 'day').startOf('day'));
    setEndDate(dayjs());
  };

  const [TaskData, setTaskData] = useState([]);

    useEffect(() => {
      getTaskData();
    }, [startDate, endDate]);

    const getTaskData = async () => {
      setLoading(true);
      let apiParams = {
        pagination: false,
        start_date: startDate && dayjs(startDate).format("YYYY-MM-DD"),
        end_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
        is_report: true,
      };
      let successFn = function (result) {
        setTaskData(result);
        setLoading(false);
      };

      let errorFn = function (error) {
        handleErrorResponse(error);
        setLoading(false);
      };
      await getAPI(TASK_REPORT_URL, successFn, errorFn, apiParams);
    };

  return (
    <Card
      loading={loading}
      bordered={false}
      bodyStyle={{ padding: 8, marginTop: 4 }}
      style={{ borderRadius: "15px", borderColor: "#ffff" }}
      title={
        <Text
          style={{
            color: "#363562",
            fontWeight: "bold",
            fontSize: "12px",
            fontFamily: "Nunito Sans",
          }}
        >
          Task Status
        </Text>
      }
      extra={
        <div style={{ display: "flex", alignItems: "center" }}>
          <RangePicker
            allowClear={false}
            value={[startDate, endDate]}
            className="range-picker"
            format={"DD/MM/YYYY"}
            onChange={onChangeDateRange}
          />
          <Button
            type="primary"
            onClick={handleReset}
            icon={<ReloadOutlined />}
            style={{ marginLeft: '10px' }}
          />
    </div>
      }
    >
      <Row gutter={[10, 10]}>
        <Col sm={24} lg={8} md={8}>
          <Card
            hoverable={true}
            bodyStyle={{
              backgroundColor: "#F6F6F6",
              border: "none",
              padding: 12,
              minHeight: "100px",
            }}
            style={{ border: "none" }}
          >
            <Row align="middle">
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "1.7rem",
                    width: "1.7rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  <img
                    src="https://partner.plutonic.co.in/pending_orders_icon.svg"
                    alt="open"
                    height={"90%"}
                  />
                </div>
              </Col>
              <Col span={18}>
                <Typography.Text className="order-card-content-number">
                  {TaskData?.open ? TaskData.open : 0}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text style={{ color: "#6C6C93" }} className="text-3">
                  Open
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col sm={24} lg={8} md={8}>
          <Card
            hoverable={true}
            bodyStyle={{
              backgroundColor: "#F6F6F6",
              border: "none",
              padding: 12,
              minHeight: "100px",
            }}
            style={{ border: "none" }}
          >
            <Row align="middle">
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "1.7rem",
                    width: "1.7rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  <img
                    src="https://partner.plutonic.co.in/In_Progress_icon.png"
                    alt="to-discuss"
                    height={"90%"}
                  />
                </div>
              </Col>
              <Col span={18}>
                <Typography.Text className="order-card-content-number">
                  {TaskData?.to_discuss ? TaskData.to_discuss : 0}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text style={{ color: "#6C6C93" }} className="text-3">
                  To Discuss
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col sm={24} lg={8} md={8}>
          <Card
            hoverable={true}
            bodyStyle={{
              backgroundColor: "#F6F6F6",
              border: "none",
              padding: 12,
              minHeight: "100px",
            }}
            style={{ border: "none" }}
          >
            <Row align="middle">
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "1.7rem",
                    width: "1.7rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  <img
                    src="https://partner.plutonic.co.in/In_Progress_icon.png"
                    alt="pending"
                    height={"90%"}
                  />
                </div>
              </Col>
              <Col span={18}>
                <Typography.Text className="order-card-content-number">
                  {TaskData?.pending  ? TaskData.pending : 0}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text style={{ color: "#6C6C93" }} className="text-3">
                  Pending
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col sm={24} lg={8} md={8}>
          <Card
            hoverable={true}
            bodyStyle={{
              backgroundColor: "#F6F6F6",
              border: "none",
              padding: 12,
              minHeight: "100px",
            }}
            style={{ border: "none" }}
          >
            <Row align="middle">
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "1.7rem",
                    width: "1.7rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  <img
                    src="https://partner.plutonic.co.in/In_Progress_icon.png"
                    alt="in-progress"
                    height={"90%"}
                  />
                </div>
              </Col>
              <Col span={18}>
                <Typography.Text className="order-card-content-number">
                  {TaskData?.in_progress  ? TaskData.in_progress : 0}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text style={{ color: "#6C6C93" }} className="text-3">
                  In Progress
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col sm={24} lg={8} md={8}>
          <Card
            hoverable={true}
            bodyStyle={{
              backgroundColor: "#F6F6F6",
              border: "none",
              padding: 12,
              minHeight: "100px",
            }}
            style={{ border: "none" }}
          >
            <Row align="middle">
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "1.7rem",
                    width: "1.7rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  <img
                    src="https://partner.plutonic.co.in/pending_orders_icon.svg"
                    alt="blocked"
                    height={"90%"}
                  />
                </div>
              </Col>
              <Col span={18}>
                <Typography.Text className="order-card-content-number">
                  {TaskData?.blocked ? TaskData.blocked : 0}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text style={{ color: "#6C6C93" }} className="text-3">
                  Blocked
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col sm={24} lg={8} md={8}>
          <Card
            hoverable={true}
            bodyStyle={{
              backgroundColor: "#F6F6F6",
              border: "none",
              padding: 12,
              minHeight: "100px",
            }}
            style={{ border: "none" }}
          >
            <Row align="middle">
              <Col span={6} style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "1.7rem",
                    width: "1.7rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  <img
                    src="https://partner.plutonic.co.in/pending_orders_icon.svg"
                    alt="over_due"
                    height={"90%"}
                  />
                </div>
              </Col>
              <Col span={18}>
                <Typography.Text className="order-card-content-number">
                  {TaskData?.over_due  ? TaskData.over_due : 0}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text style={{ color: "#6C6C93" }} className="text-3">
                  Over Due
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default TaskStatus;
