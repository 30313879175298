import React, { useEffect, useState } from "react";
import {
    Avatar, Button,
    Card,
    Col,
    DatePicker,
    Input,
    List,
    Row, Select,
    Spin,
} from "antd";
import { getAPI } from "../../../utils/apiRequest";
import { NOTIFICATION_VIEW_URL } from "../../../constants/api";
import {ClockCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import CustomPagination from "../../common/CustomPagination";
import dayjs from "dayjs";
import {handleErrorResponse} from "../../../utils/common";
import {PERIOD_CHOICES} from "../../../constants/dataKeys";
const { RangePicker } = DatePicker;

const Notifications = () => {
    const [notificationList, setNotificationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterKeys, setFilterKeys] = useState({});
    const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').startOf('day'));
    const [endDate, setEndDate] = useState(dayjs());
    const [count, setCount] = useState(1);
    const [nextPage, setnextPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getNotifications();
    }, [endDate, filterKeys]);

    const filterData = (type, value) => {
        setFilterKeys((filterKeys) => {
            return { ...filterKeys, [type]: value ? value : undefined };
        });
    };

    const getNotifications = (page=1) => {
        setLoading(true);
        let apiParams = {
            page,
            start_date: startDate && dayjs(startDate).format("YYYY-MM-DD"),
            end_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
            ...filterKeys
        };
        const successFn = (result) => {
            const {next, current, count} = result;
            setNotificationList([...result.results]);
            setCount(count);
            setCurrentPage(current);
            setnextPage(next);
            setLoading(false);
        };
        const errorFn = (error) => {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(NOTIFICATION_VIEW_URL, successFn, errorFn, apiParams);
    };



    const onChangeDateRange = (dates, dateStrings) => {
        setStartDate(dates && dates[0]);
        setEndDate(dates && dates[1]);
    };

    const handleReset = () => {
        setFilterKeys({});
        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
        setEndDate(dayjs());
    };

    return (
        <div>
            <Spin spinning={loading}>
                <div className='container-project' style={{
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    overflowX: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div className='filter-container' style={{
                        marginBottom: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <h2 style={{marginBottom: '20px'}}>Notifications List</h2>
                        <div>
                            <RangePicker
                                style={{marginRight: '10px'}}
                                allowClear={false}
                                value={[startDate, endDate]}
                                className="range-picker"
                                format={"DD/MM/YYYY"}
                                onChange={onChangeDateRange}
                            />
                            <Select
                                style={{ width: 150, marginRight: '10px' }}
                                onChange={(e) => {
                                    filterData('period', e);
                                    if (e === undefined) {
                                        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
                                        setEndDate(dayjs());
                                    } else {
                                        setStartDate(); setEndDate();
                                    }
                                }}
                                placeholder="Select Period"
                                value={filterKeys?.period}
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                            >
                                {PERIOD_CHOICES?.map((option) => (
                                    <Select.Option key={option.key} value={option.key} label={option.name}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Input style={{width: 150, marginRight: '10px'}} placeholder="Content"
                                   value={filterKeys?.content__icontains}
                                   onChange={(e) => filterData('content__icontains', e.target.value)}/>
                            <Button type="primary" onClick={handleReset} icon={<ReloadOutlined/>}
                                    style={{marginLeft: '10px'}}></Button>
                        </div>
                    </div>
                        <Card>
                            <Row
                            justify="center"
                            style={{ height: "60vh", overflow: "auto" }}
                            >
                                <Col span={24}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={notificationList}
                                        renderItem={(item) => (
                                            <List.Item className="notification-item">
                                                <List.Item.Meta
                                                    avatar={<Avatar icon={<ClockCircleOutlined />} />}
                                                    title={item.content}
                                                    description={dayjs(
                                                        item.created_at
                                                    ).format("DD/MM/YYYY h:mm A")}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    <CustomPagination
                        nextPage={nextPage}
                        count={count}
                        currentPage={currentPage}
                        loadData={getNotifications}
                    />
                </div>
            </Spin>
        </div>
    );
};

export default Notifications;
