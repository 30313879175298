import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { EMPLOYEE_TASK_REPORT_URL, PROJECT_URL, EMPLOYEE_URL} from "../../../constants/api";
import {getAPI} from '../../../utils/apiRequest/index';
import { handleErrorResponse } from "../../../utils/common";
import { Table, Spin, Select, Button, DatePicker, Tag} from 'antd';
import { ReloadOutlined } from "@ant-design/icons";
import {getStatusColor, PERIOD_CHOICES} from "../../../constants/dataKeys";

const { RangePicker } = DatePicker;

const EmployeeTaskReport = () => {
  const [TaskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterKeys, setFilterKeys] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [assigned_toList, setAssignedTo] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  useEffect(() => {
    getProject();
    getAssignedTo();
  }, []);

  const onChangeDateRange = (dates, dateStrings) => {
    setStartDate(dates && dates[0]);
    setEndDate(dates && dates[1]);
      setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          period: null,
      }));
  };

  const handleReset = () => {
    setFilterKeys({});
    setStartDate(dayjs().subtract(7, 'day').startOf('day'));
    setEndDate(dayjs());
    setSelectedProject([]);
    setSelectedEmployee([]);
  };

  useEffect(() => {
    getTaskData();
  }, [filterKeys, startDate, endDate]);

  const filterData = (type, value) => {

    setFilterKeys((filterKeys) => {
        return {...filterKeys, [type]: value ? value : undefined};
    });

};
  const filterMultipleData = (type, value) => {
        setFilterKeys((filterKeys) => {
            const joinedValue = value ? value.join(',') : undefined;
            return { ...filterKeys, [type]: joinedValue };
        });
  };

const getProject = () => {
  setLoading(true);
  let apiParams = {
    pagination: false,
    request_size: "low"
  };

  let successFn = function (result) {
    setProjectList(result);
    setLoading(false);
  };

  let errorFn = function (error) {
    handleErrorResponse(error);
    setLoading(false);
  };
  getAPI(PROJECT_URL, successFn, errorFn, apiParams);
};

const getAssignedTo = () => {
  setLoading(true);
  let apiParams = {
    pagination: false,
    request_size: "low"
  };

  let successFn = function (result) {
    setAssignedTo(result);
    setLoading(false);
  };

  let errorFn = function (error) {
    handleErrorResponse(error);
    setLoading(false);
  };
  getAPI(EMPLOYEE_URL, successFn, errorFn, apiParams);
};

  const getTaskData = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      start_date: startDate && dayjs(startDate).format("YYYY-MM-DD"),
      end_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
      ...filterKeys,
    };

    let successFn = function (result) {
      setTaskData(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(EMPLOYEE_TASK_REPORT_URL, successFn, errorFn, apiParams);
  };

  const columns = [
    {
        title: 'S.No',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (text, record, index) => index + 1,
        width: 50,
    },
    {
        title: 'Employee Name',
        dataIndex: 'assigned_to__name',
        key: 'assigned_to__name',
        ellipsis: true,
        render: (assigned_to__name) => assigned_to__name || 'N/A',
    },
    {
        title: <Tag color={getStatusColor('Open')}>Open</Tag>,
        dataIndex: 'open',
        key: 'open',
        ellipsis: true,
        render: (open) => open || '0',
        },
    {
        title: <Tag color={getStatusColor('To Discuss')}>To Discuss</Tag>,
        dataIndex: 'to_discuss',
        key: 'to_discuss',
        ellipsis: true,
        render: (to_discuss) => to_discuss || '0',
        },
    {
        title: <Tag color={getStatusColor('Pending')}>Pending</Tag>,
        dataIndex: 'pending',
        key: 'pending',
        ellipsis: true,
        render: (pending) => pending || '0',
        },
    {
        title: <Tag color={getStatusColor('In Progress')}>In Progress</Tag>,
        dataIndex: 'in_progress',
        key: 'in_progress',
        ellipsis: true,
        render: (in_progress) => in_progress || '0',
        },
    {
        title: <Tag color={getStatusColor('Blocked')}>Blocked</Tag>,
        dataIndex: 'blocked',
        key: 'blocked',
        ellipsis: true,
        render: (blocked) => blocked || '0',
        },
    {
        title: <Tag color={getStatusColor('Completed')}>Completed</Tag>,
        dataIndex: 'completed',
        key: 'completed',
        ellipsis: true,
        render: (completed) => completed || '0',
        },
    {
        title: <Tag color={getStatusColor('Over Due')}>Over Due</Tag>,
        dataIndex: 'over_due',
        key: 'over_due',
        ellipsis: true,
        render: (over_due) => over_due || '0',
        },
    {
        title: <Tag color={getStatusColor('Ready for Testing')}>Ready for Testing</Tag>,
        dataIndex: 'ready_for_testing',
        key: 'ready_for_testing',
        ellipsis: true,
        render: (ready_for_testing) => ready_for_testing || '0',
        },
    {
        title: <Tag color={getStatusColor('Closed')}>Closed</Tag>,
        dataIndex: 'closed',
        key: 'closed',
        ellipsis: true,
        render: (closed) => closed || '0',
        },
  ];

  return (
    <div style={{ margin: '20px' }}>
      <Spin spinning={loading}>
      <div className='container-task' style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', overflow: 'auto', maxHeight: '80vh'}}>
      <div className='filter-container' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 style={{marginBottom: '20px' }}>Employee Task Report</h2>
        <div>
          <RangePicker
            style={{marginRight: '10px'}}
            allowClear={false}
            value={[startDate, endDate]}
            className="range-picker"
            format={"DD/MM/YYYY"}
            onChange={onChangeDateRange}
          />
            <Select
                style={{ width: 150, marginRight: '10px' }}
                onChange={(e) => {
                    filterData('period', e);
                    if (e === undefined) {
                        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
                        setEndDate(dayjs());
                    } else {
                        setStartDate(); setEndDate();
                    }
                }}
                placeholder="Select Period"
                value={filterKeys?.period}
                optionFilterProp={"children"}
                showSearch={true}
                allowClear={true}
            >
                {PERIOD_CHOICES?.map((option) => (
                    <Select.Option key={option.key} value={option.key} label={option.name}>
                        {option.name}
                    </Select.Option>
                ))}
            </Select>
          <Select
            style={{ width: 150, marginRight: '10px' }}
            value={selectedProject}
            onChange={(value) => {
                setSelectedProject(value);
                filterMultipleData('project', value);
            }}
            placeholder="Select Project"
            optionFilterProp={"children"}
            showSearch={true}
            allowClear={true}
            mode={"multiple"}
          >
            {projectList?.map((option) => (
                            <Select.Option
                                label={option.name}
                                key={option.id}
                                value={option.id}
                            >
                                {option.name}
                            </Select.Option>
                        ))}
          </Select>
          <Select
            style={{ width: 150, marginRight: '10px' }}
            value={selectedEmployee}
            onChange={(value) => {
                setSelectedEmployee(value);
                filterMultipleData('assigned_to', value);
            }}
            placeholder="Select Employee"
            optionFilterProp={"children"}
            showSearch={true}
            allowClear={true}
            mode={"multiple"}
          >
            {assigned_toList?.map((option) => (
                            <Select.Option
                                label={option.name}
                                key={option.id}
                                value={option.id}
                            >
                                {option.name}
                            </Select.Option>
                        ))}
          </Select>
          <Button type="primary" onClick={handleReset} icon={<ReloadOutlined />} style={{ marginLeft: '10px' }}></Button>
          </div>
        </div>
          <Table
            dataSource={TaskData}
            columns={columns}
            pagination={{ pageSize: 15 }}
            bordered
            size="middle"
            scroll={{ x: 'max-content' }}
          />
        </div>
        </Spin>
    </div>
  );
};

export default EmployeeTaskReport;
