import React, { useState} from "react";
import { CHANGE_PASSWORD_URL } from "../../../constants/api";
import {postAPI} from '../../../utils/apiRequest/index';
import { useNavigate } from 'react-router-dom';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE, WARNING_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn} from '../../../utils/common';
import { Form, Input, Button, Row, Col, Card } from 'antd';


const ChangePassword = () => {

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    const { old_password, new_password, c_new_password} = values;

    if(old_password==null || new_password==null || c_new_password==null){
        emptyFn();
    }
    else if (new_password !== c_new_password) {
        displayMessage(WARNING_MSG_TYPE, 'New Password do not match!');
        return;
      }
    else{
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'Password Changed Successfully.');
        setLoading(false);
        form.resetFields();
        localStorage.clear('refresh');
        localStorage.clear('access');
        displayMessage(SUCCESS_MSG_TYPE, 'Logout Successfully.');
        navigate("/");
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
        setLoading(false);
    };

    postAPI(CHANGE_PASSWORD_URL, values, successFn, errorFn);
}
  };

  return (
    <Card style={{padding:24}}>
    <h2 style={{marginBottom: '40px' }}>Change Password</h2>
    <Form onFinish={handleSubmit} labelCol={{ span: 4 }} wrapperCol={{ span: 10 }} form={form}>
    <Row>
    <Col xl={24} lg={24} md={24} sm={24}>
    <Form.Item label="Old Password" name={"old_password"} rules={[{required: true}]}>
        <Input.Password placeholder="Old Password"/>
      </Form.Item>
    </Col>

    <Col xl={24} lg={24} md={24} sm={24}>
      <Form.Item label="New Password" name={"new_password"} rules={[{required: true}]}>
        <Input.Password placeholder="New Password"/>
      </Form.Item>
    </Col>

    <Col xl={24} lg={24} md={24} sm={24}>
      <Form.Item label="Confirm New Password" name={"c_new_password"} rules={[{required: true}]}>
        <Input.Password placeholder="Confirm New Password"/>
      </Form.Item>
    </Col>
    </Row>
    <Col span={24} style={{textAlign:"center"}}>
    <Form.Item>
    <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
    {loading ? "Changing..." : "Save Changes"}
    </Button>
  </Form.Item>
</Col>
</Form>
</Card>
  );
};
export default ChangePassword;
