import React, {useEffect} from 'react';
import SidebarNavbar from './Sidebar';
import {ERROR_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage} from '../../../utils/common';
import { useNavigate } from 'react-router-dom';
import { Row, Col} from 'antd';
import MyTask from './MyTask';
import TaskStatus from "./TaskStatus";
import Welcome from "./welcome";


const Dashboard = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access');

        if(token === null){
            displayMessage(ERROR_MSG_TYPE, "Unauthorized!!");
            navigate('/');
        }
      }, []);

  return (
    <SidebarNavbar>
            <Row gutter={[16, 16]}>
                    <Col span={24}>
                        &nbsp;
                    </Col>
                <Col span={16}>
                    <Row gutter={[16, 16]}>
                        <Col span={24} className={"mb-10"}>
                            <Welcome/>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <TaskStatus/>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{marginTop: 8}}>
                <Col lg={24} md={24} style={{height: "auto", width: "100%"}}>
                  <MyTask/>
                </Col>
            </Row>
        </SidebarNavbar>
    );
};

export default Dashboard;