import dayjs from 'dayjs';
import React, { useState, useEffect} from "react";
import "../../../assets/css/Custom.css";
import { useLocation } from 'react-router-dom';
import { EMPLOYEE_URL, DYNAMIC_URL, TASK_URL, PROJECT_URL } from "../../../constants/api";
import {putAPI, getAPI} from '../../../utils/apiRequest/index';
import {SUCCESS_MSG_TYPE, LABEL, PRIORITY, STATUS, TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import {
    Form,
    Select,
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Card,
    Checkbox,
    Space,
    Divider,
    Modal
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import UploadFile from '../../common/uploadFile';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UpdateTask = () => {
    const [formData, setFormData] = useState({
      id:"",
      project: null,
      name: "",
      description: "",
      label: "",
      type: "",
      status: "",
      priority: "",
      due_date: "",
      reporter: "",
      assigned_to: "",
      document: "",
      is_active: ""
    });

    const [form] = Form.useForm();
    const [taskList, setTaskList] = useState([]);
    const [filterKeys, setFilterKeys] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [labelList, setLabel] = useState([]);
    const [typeList, setType] = useState([]);
    const [statusList, setStatus] = useState([]);
    const [priorityList, setPriority] = useState([]);
    const [employeeList, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [descriptionVal, setDescriptionVal] = useState("");
    const location = useLocation();
    const [isDocModalOpen, setIsDocModalOpen] = useState(false);
    const [uploadFileStateMultiple, setUploadFileStateMultiple] = useState({});

    const onEditorStateChange = (name, content) => {
      setDescriptionVal(content);
    };

    useEffect(() => {
      if (!loading) {
        setDescriptionVal("");
      }
    }, [loading]);

    const handleInputChange = (type, values) => {
      setFormData((formData)=>{
        return{...formData, [type]:values ? values : null}
      });
    };

    const handleTaskSelect = (TaskValue, is_update=false) => {
        let selectedTask={};
        if(is_update){
            selectedTask = taskList.filter((task) => task.id === Number(TaskValue))[0];
        }
        else{
            selectedTask = TaskValue;
        }
        if(selectedTask){
        form.setFieldsValue({
            id: selectedTask?.id || "",
            task: selectedTask?.id || "",
            name: selectedTask?.name || "",
            project: selectedTask?.project || "",
            label: selectedTask?.label || "",
            type: selectedTask?.type || "",
            status: selectedTask?.status || "",
            priority: selectedTask?.priority || "",
            reporter: selectedTask?.reporter || "",
            assigned_to: selectedTask?.assigned_to || "",
            description: selectedTask?.description || "",
            due_date: selectedTask?.due_date ? dayjs(selectedTask?.due_date,"YYYY-MM-DD") : undefined,
          });
          setFormData(selectedTask);
        }
      };

    useEffect(() => {
        getProject();
        getLabel();
        getType();
        getStatus();
        getPriority();
        getEmployee();
        if(location?.state && location?.state?.editData){
            handleTaskSelect(location?.state?.editData);
        }
      }, []);

      useEffect(() => {
        getTask();
      }, [filterKeys]);

      const filterData = (type, value) => {

          setFilterKeys((filterKeys) => {
              return {...filterKeys, [type]: value ? value : undefined};
          });

      };

      const getProject = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          request_size: "low"
        };

        let successFn = function (result) {
          setProjectList(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(PROJECT_URL, successFn, errorFn, apiParams);
      };

      const getTask = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          ...filterKeys,
        };

        let successFn = function (result) {
          setTaskList(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(TASK_URL, successFn, errorFn, apiParams);
      };

      const getLabel = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          name:LABEL,
        };

        let successFn = function (result) {
          setLabel(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
      };

      const getStatus = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          name:STATUS,
        };

        let successFn = function (result) {
          setStatus(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
      };

      const getPriority = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          name:PRIORITY,
        };

        let successFn = function (result) {
          setPriority(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
      };

      const getType = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          name:TYPE,
        };

        let successFn = function (result) {
          setType(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
      };

      const getEmployee = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          request_size: "low"
        };

        let successFn = function (result) {
          setEmployee(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(EMPLOYEE_URL, successFn, errorFn, apiParams);
      };


      const handleSubmit = async (values) => {
        const { label, type, status, priority, reporter, assigned_to, due_date, name } = values;
        const modifiedDueDate = due_date ? dayjs(due_date).format("YYYY-MM-DD") : undefined;
        let documents = [];
        if (uploadFileStateMultiple['documents']) {
            Object.keys(uploadFileStateMultiple['documents']).map((file, index) => {
                  documents.push({file: uploadFileStateMultiple['documents'][file]?.is_Upload ? uploadFileStateMultiple['documents'][file].file_name : uploadFileStateMultiple['documents'][file].id})
            })
        }
        values.documents = documents
        values.due_date = modifiedDueDate;
        values.id = formData.id;
        values.name = formData.name;
        values.is_active ? (values.is_active = false) : delete values.is_active;

        if(name==null || label==null || type==null || status==null || priority==null || reporter==null || assigned_to==null){
            emptyFn();
        }
        else{
        setLoading(true);
        let successFn = function (result) {
            displayMessage(SUCCESS_MSG_TYPE, 'Task Status Updated!');
            setLoading(false);
            form.resetFields();
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };

        putAPI(TASK_URL, values, successFn, errorFn);
    }
};

const toggleDocModal = (value) => {
    setIsDocModalOpen(value)
}

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};

return (
  <Card style={{padding:24}}>
      <h2 style={{marginBottom: '40px' }}>Update Task</h2>
      <Form onFinish={handleSubmit} labelCol={{span: 4,}} wrapperCol={{span: 20,}} form={form}>

      <Row>
          <Col xl={12} lg={12} md={24} sm={24}>
            <Form.Item label={"Project"} name={"project"} rules={[{required: true}]}>
              <Select placeholder={"Project"} optionFilterProp={"children"} showSearch={true}
                      onChange={(value) => {
                  handleInputChange("project", value);
                  filterData("project", value);
                  const fieldsToReset = Object.keys(form.getFieldsValue())
                      .filter(field => field !== "project");
                  form.resetFields(fieldsToReset);
              }}
              >
                {projectList.map((project) => (
                  <Select.Option label={project.id} value={project.id}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xl={11} lg={11} md={24} sm={24} offset={1}>
              <Form.Item label={"Task"} name={"task"} rules={[{required: true}]} shouldUpdate={(prevValues, currentValues) => prevValues.project !== currentValues.project}>
                  <Select placeholder={"Task"} onChange={(value) => {handleTaskSelect(value, true)}} disabled={!form.getFieldValue("project")} optionFilterProp={"children"} showSearch={true}>
              {taskList.map((task) => (
                <Select.Option label={task.id} value={task.id}>
                  {task.task_id+ ": " + task.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
          <Form.Item label={"Label"} name={"label"} rules={[{required: true}]}>
            <Select placeholder={"Label"} onChange={(value) => handleInputChange("label", value)} optionFilterProp={"children"} showSearch={true}>
              {labelList.map((label) => (
                <Select.Option key={label.id} value={label.id} label={label.value}>
                  {label.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
          <Form.Item label={"Type"} name={"type"} rules={[{required: true}]} >
            <Select placeholder={"Type"} onChange={(value) => handleInputChange("type", value)} optionFilterProp={"children"} showSearch={true}>
              {typeList.map((type) => (
                <Select.Option label={type.id} value={type.id}>
                  {type.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24} >
          <Form.Item label={"Status"} name={"status"} rules={[{required: true}]}>
            <Select placeholder={"Status"} onChange={(value) => handleInputChange("status", value)} optionFilterProp={"children"} showSearch={true}>
              {statusList.map((status) => (
                <Select.Option label={status.id} value={status.id}>
                  {status.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
          <Form.Item label={"Priority"} name={"priority"} rules={[{required: true}]}>
            <Select placeholder={"Priority"} onChange={(value) => handleInputChange("priority", value)} optionFilterProp={"children"} showSearch={true}>
              {priorityList.map((priority) => (
                <Select.Option label={priority.id} value={priority.id}>
                  {priority.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
        <Form.Item label={"Assigned To"} name={"assigned_to"} rules={[{required: true}]}>
            <Select placeholder={"Assigned To"} onChange={(value) => handleInputChange("assigned_to", value)} optionFilterProp={"children"} showSearch={true}>
              {employeeList.map((assigned_to) => (
                <Select.Option label={assigned_to.id} value={assigned_to.id}>
                  {assigned_to.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
          <Form.Item label={"Reporter"} name={"reporter"}>
            <Select placeholder={"Reporter"} onChange={(value) => handleInputChange("reporter", value)} optionFilterProp={"children"} showSearch={true}>
              {employeeList.map((reporter) => (
                <Select.Option label={reporter.id} value={reporter.id}>
                  {reporter.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
          <Form.Item label="Title" name={"name"} rules={[{required: true}]}>
            <Input placeholder="Task Title"/>
          </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
            <Form.Item label="Due Date" name={"due_date"}>
                <DatePicker
                    placeholder="Due Date"
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    disabledDate={(current) => current && current < dayjs().subtract(1, 'day')}
                />
            </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
          <Form.Item label="Description" name={"description"}>
            <ReactQuill
                value={descriptionVal}
                onChange={(content) =>
                    onEditorStateChange("description", content)
                }
                modules={{
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{header: 1}, {header: 2}],
                        [{list: "ordered"}, {list: "bullet"}],
                        [{script: "sub"}, {script: "super"}],
                        [{indent: "-1"}, {indent: "+1"}],
                        [{direction: "rtl"}],
                        [{size: ["small", false, "large", "huge"]}],
                        [{header: [1, 2, 3, 4, 5, 6, false]}],
                        [{color: []}, {background: []}],
                        [{font: []}],
                        [{align: []}],
                        ["clean"],
                    ],
                }}
            />
          </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
            <Form.Item label="Documents" name="document" >
                <Button style={{marginTop: '-30px'}} size={'large'} disabled={false} onClick={() => toggleDocModal(true)}>
                    <PlusOutlined/> Upload
                </Button>
            </Form.Item>
        <Form.Item name="is_active" valuePropName="checked" initialValue={false} noStyle>
            <Checkbox>Delete Task</Checkbox>
        </Form.Item>
        </Col>

        <Col span={24} style={{textAlign:"center"}}>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
              {loading ? 'Updating...' : 'Update'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
      <Modal open={isDocModalOpen} footer={null} onCancel={() => setIsDocModalOpen(false)} width={700}>
          <Form form={form}>
              <Form.List name={"documents"}>
                  {(fields, { add, remove }) => (
                      <Form.Item label={"Documents"}>
                          {fields.map((field, index) => (
                              <Space
                                  key={index}
                                  style={{ alignItems: "baseline", width: '100%' }}
                                  size={70}
                              >
                                  <Row>
                                      <Col span={23}>
                                          <Form.Item>
                                              <UploadFile
                                                  isMultiple={true}
                                                  keyName={'documents'}
                                                  key={`Upload_document${index}`}
                                                  labelCol={{ span: 10 }}
                                                  {...field}
                                                  name={[field.name, "documents"]}
                                                  extra={uploadFileStateMultiple['documents'] && uploadFileStateMultiple['documents'][`${field.name},documents`] && uploadFileStateMultiple['documents'][`${field.name},documents`]?.is_Upload ? uploadFileStateMultiple['documents'][`${field.name},documents`]?.file_name : null}
                                                  label={`Document ${index + 1}`}
                                                  setState={setUploadFileStateMultiple}
                                                  setLoading={setLoading}
                                              />
                                          </Form.Item>
                                      </Col>
                                      <Col span={1} style={{ paddingLeft: "10px" }}>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                      </Col>
                                  </Row>
                                  <Divider />
                              </Space>
                          ))}
                          <Form.Item style={{ paddingTop: "30px" }}>
                              <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                              >
                                  Add Document
                              </Button>
                          </Form.Item>
                          <Form.Item {...tailLayout}>
                              <Space>
                                  <Button type={'primary'} className={'theme-color'}  onClick={() => setIsDocModalOpen(false)}>Save</Button>
                              </Space>
                          </Form.Item>
                      </Form.Item>
                  )}
              </Form.List>
          </Form>
      </Modal>
    </Card>
  );
};
export default UpdateTask;
