import React, { useState, useEffect } from "react";
import "../../../assets/css/Custom.css";
import { WORK_LOG_URL} from "../../../constants/api";
import {putAPI} from '../../../utils/apiRequest/index';
import {SUCCESS_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import {Form, Button, Row, Col, Card, TimePicker, Input} from 'antd';
import dayjs from 'dayjs';
import {useLocation, useNavigate} from "react-router-dom";

const UpdateWorkLog = () => {
    const [formData, setFormData] = useState({
        id:"",
        project: null,
        task: null,
        time_spend: dayjs("00:00", "HH:mm"),
    });

    const [form] = Form.useForm();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleWorkLogSelect = (selectedWorkLog, is_update=false) => {
        if(selectedWorkLog){
            form.setFieldsValue({
                id: selectedWorkLog?.id || "",
                project_name: selectedWorkLog?.project_data?.name || "",
                task_name: selectedWorkLog?.task_data?.name || "",
                time_spend: dayjs(selectedWorkLog?.time_spend, 'HH:mm') || null,
            });
            setFormData(selectedWorkLog);
        }
    };

    useEffect(() => {
        if(location?.state && location?.state?.editData){
            handleWorkLogSelect(location?.state?.editData);
        }
    },[]);
    const handleSubmit = async (values) => {
        const { time_spend } = values;
        const formattedTimeSpend = time_spend ? time_spend.format("HH:mm") : undefined;
        values.id = formData.id;
        values.task = formData.task;
        values.project = formData.project;
        values.time_spend = formattedTimeSpend;
        if(time_spend==null){
            emptyFn();
        }
        else{
            setLoading(true);
            let successFn = function (result) {
                displayMessage(SUCCESS_MSG_TYPE, 'Work Log Updated!');
                setLoading(false);
                form.resetFields();
                navigate("/dashboard/viewworklog/");
            };

            let errorFn = function (error) {
                handleErrorResponse(error);
                setLoading(false);
            };

            putAPI(WORK_LOG_URL, values, successFn, errorFn);
        }
    };

    return (
        <Card style={{padding:24}}>
            <h2 style={{marginBottom: '40px' }}>Update Worklog</h2>
            <Form onFinish={handleSubmit} labelCol={{span: 3}} wrapperCol={{span: 9}} form={form}>
                <Row>
                    <Col span={24}>
                        <Form.Item label={"Project"} name={"project_name"} >
                            <Input placeholder="Project" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={"Task"} name={"task_name"}>
                            <Input placeholder="Task" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Work Log" name="time_spend" rules={[{required: true}]}>
                            <TimePicker placeholder="Time Spend" format="HH:mm" />
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{textAlign:"center"}}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
                                {loading ? 'Updating...' : 'Update'}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
export default UpdateWorkLog;
