import React, { useState} from "react";
import { PASSWORD_RESET_URL } from "../../../constants/api";
import {postAPI} from '../../../utils/apiRequest/index';
import { useNavigate, useParams } from 'react-router-dom';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE, WARNING_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn} from '../../../utils/common';
import { Form, Input, Button, Row, Col, Card } from 'antd';


const ResetPassword = () => {

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    const {password, c_password} = values;
    values.code = token;

    if(password==null || c_password==null){
        emptyFn();
    }
    else if (password !== c_password) {
        displayMessage(WARNING_MSG_TYPE, 'Password do not match!');
        return;
      }
    else{
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'Password Reset Successfully.');
        setLoading(false);
        form.resetFields();
        localStorage.clear('refresh');
        localStorage.clear('access');
        navigate("/");
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
        setLoading(false);
    };

    postAPI(PASSWORD_RESET_URL, values, successFn, errorFn);
}
  };

  return (
    <Card style={{padding:24}}>
    <h2 style={{marginBottom: '40px' }}>Reset Password</h2>
    <Form onFinish={handleSubmit} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form}>
    <Row>

    <Col xl={24} lg={24} md={24} sm={24}>
      <Form.Item label="New Password" name={"password"} rules={[{required: true}]}>
        <Input.Password placeholder="New Password"/>
      </Form.Item>
    </Col>

    <Col xl={24} lg={24} md={24} sm={24}>
      <Form.Item label="Confirm New Password" name={"c_password"} rules={[{required: true}]}>
        <Input.Password placeholder="Confirm New Password"/>
      </Form.Item>
    </Col>
    </Row>
    <Col span={24} style={{textAlign:"center"}}>
    <Form.Item>
    <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
    {loading ? "Reseting..." : "Reset"}
    </Button>
  </Form.Item>
</Col>
</Form>
</Card>
  );
};
export default ResetPassword;
