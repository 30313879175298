import "../../assets/css/Custom.css";
import React, { useState, useEffect } from "react";
import { LOGIN, PASSWORD_RESET_MAIL_URL } from "../../constants/api";
import { Link, useNavigate } from "react-router-dom";
import {postWithOutTokenAPI} from '../../utils/apiRequest/index';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from '../../constants/dataKeys';
import {displayMessage, emptyFn} from '../../utils/common';
import { Modal, Input, Form, Button, Divider, Row, Col, Spin } from 'antd';
import {LockOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';
import AuthBase from './AuthBase';

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setResetEmail] = useState("");
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('access');

    if(token !== null){
        navigate('/dashboard');
    }
  }, []);

  const handleForgotPassword = () => {
    setForgotPasswordModalVisible(true);
  };

  const handleCancelForgotPasswordModal = () => {
    setForgotPasswordModalVisible(false);
  };

  const handleReset = async () => {
    if(email===""){
        emptyFn();
    }
    else{
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'Password Reset Mail Sent Successfully!');
        setLoading(false);
        setForgotPasswordModalVisible(false);
        setResetEmail("");
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
        setLoading(false);
    };
    postWithOutTokenAPI(PASSWORD_RESET_MAIL_URL, { username: email }, successFn, errorFn);
  }
}

  const handleLogin = async (values) => {
    const {username, password} = values;

    if(username==="" || password===""){
        emptyFn();
    }
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'User LoggedIn Successfully.');
        setLoading(false);
        localStorage.setItem("access", result.access);
        localStorage.setItem("refresh", result.refresh);
        navigate("/dashboard");
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, item));
        setLoading(false);
    };

    postWithOutTokenAPI(LOGIN, values, successFn, errorFn);
  };

return(
    <AuthBase>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div>
                    <h2>Login to your Account</h2>
                    <p style={{marginBottom: "2em"}}>Enter your email and password to login</p>
                    <br/>
                    <Spin spinning={loading}>
                        <Form
                            id={'components-form-demo-normal-login'}
                            size={'large'}
                            onFinish={handleLogin}
                        >
                                <Form.Item name='username'>
                                    <Input prefix={<UserOutlined style={{color: "#00000040"}}/>}
                                        placeholder='Email id'
                                        />
                                </Form.Item>
                                <Form.Item name='password'>
                                    <Input.Password
                                        prefix={<LockOutlined style={{color: "#00000040"}}/>}
                                        placeholder='Password'
                                    />
                                </Form.Item>
                            <br/>
                            <Form.Item>
                                <Button
                                    type={'primary'}
                                    size={'large'}
                                    htmlType='submit'
                                    className='login-form-button primary-btn'
                                    shape="round">
                                Log In
                                </Button>
                            </Form.Item>
                            <Divider>
                              <Link className="forgot-password-link" onClick={handleForgotPassword} style={{ color: "#31A6C7", textDecoration: "None" }}>
                                Forgot Password
                              </Link>
                            </Divider>
                        </Form>
                    </Spin>
                <Modal
                    title="Email to Reset Password"
                    visible={forgotPasswordModalVisible}
                    onCancel={handleCancelForgotPasswordModal}
                    onOk={handleReset}
                    okText='Reset Password'
                  >
                    <Input placeholder='Email id' prefix={<MailOutlined/>} onChange={(e) => setResetEmail(e.target.value)}/>
                  </Modal>
                              </div>
                    </Col>
                </Row>
    </AuthBase>
    );
};

export default Login;
