import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import {TASK_REPORT_URL, DYNAMIC_URL, PROJECT_URL, TASK_URL} from "../../../constants/api";
import {
    STATUS,
    PRIORITY,
    TYPE,
    PERIOD_CHOICES,
    DEFAULT_PAGE_SIZE,
    getStatusColor,
    getPriorityColor,
    SUCCESS_MSG_TYPE
} from '../../../constants/dataKeys';
import {getAPI, putAPI} from '../../../utils/apiRequest/index';
import {displayMessage, emptyFn, handleErrorResponse} from "../../../utils/common";
import {Table, Spin, Select, Button, Modal, Row, Col, Typography, DatePicker, Input, Tag, Form} from 'antd';
import { ReloadOutlined, TeamOutlined, UserOutlined, CalendarOutlined, MessageOutlined, GoldOutlined, ClockCircleOutlined} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomPagination from "../../common/CustomPagination";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const ViewMyTask = () => {
  const [TaskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterKeys, setFilterKeys] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [typeList, setType] = useState([]);
  const [statusList, setStatus] = useState([]);
  const [priorityList, setPriority] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [count, setCount] = useState(1);
  const [nextPage, setnextPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [form] = Form.useForm();

  const updateInitialValues = () => {
        form.setFieldsValue({
            status: selectedRowData?.status_data?.id,
        });
  };

  useEffect(() => {
        updateInitialValues();
  }, [selectedRowData]);

  const onChangeDateRange = (dates, dateStrings) => {
    setStartDate(dates && dates[0]);
    setEndDate(dates && dates[1]);
      setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          period: null,
      }));
  };

  const handleReset = () => {
    setFilterKeys({});
    setStartDate(dayjs().subtract(7, 'day').startOf('day'));
    setEndDate(dayjs());
    setSelectedProject([]);
    setSelectedPriority([]);
    setSelectedType([]);
    setSelectedStatus([]);
  };

  useEffect(() => {
    getProject();
    getType();
    getStatus();
    getPriority();
  }, []);

  useEffect(() => {
    getTaskData();
  }, [filterKeys, startDate, endDate]);

  const filterData = (type, value) => {
    setFilterKeys((filterKeys) => {
        return {...filterKeys, [type]: value ? value : undefined};
    });
  };

  const filterMultipleData = (type, value) => {
        setFilterKeys((filterKeys) => {
            const joinedValue = value ? value.join(',') : undefined;
            return { ...filterKeys, [type]: joinedValue };
        });
  };

  const getProject = () => {
    setLoading(true);
    let apiParams = {
      pagination:false,
      request_size: "low"
    };

    let successFn = function (result) {
      setProjectList(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(PROJECT_URL, successFn, errorFn, apiParams);
  };

  const getStatus = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      name:STATUS,
    };

    let successFn = function (result) {
      setStatus(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
  };

  const getPriority = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      name:PRIORITY,
    };

    let successFn = function (result) {
      setPriority(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
  };

  const getType = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      name:TYPE,
    };

    let successFn = function (result) {
      setType(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
  };

  const getTaskData = (page=1) => {
    setLoading(true);
    let apiParams = {
      page,
        page_size: DEFAULT_PAGE_SIZE,
      start_date: startDate && dayjs(startDate).format("YYYY-MM-DD"),
      end_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
      ...filterKeys,
    };
    let successFn = function (result) {
      const {next, current, count} = result;
      setTaskData(result.results);
      setCount(count);
      setCurrentPage(current);
      setnextPage(next);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(TASK_REPORT_URL, successFn, errorFn, apiParams);
  };

    const handleStatusChange = async (values) => {
        const statusData = {
            "status": values,
            "id": selectedRowData?.id,
            "project": selectedRowData?.project,
            "name": selectedRowData?.name
        }
        if (statusData.status == null) {
            emptyFn();
        } else {
            setLoading(true);
            let successFn = function (result) {
                const message = 'Task Status Updated!';
                displayMessage(SUCCESS_MSG_TYPE, message);
                setLoading(false);
                setIsModalVisible(false);
                getTaskData();
            };
            let errorFn = function (error) {
                handleErrorResponse(error);
                setLoading(false);
            };
            putAPI(TASK_URL, statusData, successFn, errorFn);
        }
    };

  const columns = [
    {
        title: 'S.No',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (text, record, index) => index + 1,
        width: 50,
    },
    {
        title: 'Project',
        dataIndex: ['project_data', 'name'],
        key: 'project',
        ellipsis: true,
        render: (project) => project || 'N/A',
      },
      {
        title: 'Task Id',
        dataIndex: 'task_id',
        key: 'task_id',
        ellipsis: true,
        render: (task_id) => task_id || 'N/A',
    },
    {
        title: 'Task Name',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        render: (name) => name || 'N/A',
    },
    {
        title: 'Label',
        dataIndex: ['label_data', 'value'],
        key: 'label',
        ellipsis: true,
        render: (label) => label || 'N/A',
        },
    {
        title: 'Issue Type',
        dataIndex: ['type_data', 'value'],
        key: 'type',
        ellipsis: true,
        render: (type) => type || 'N/A',
        },
    {
        title: 'Status',
        dataIndex: ['status_data', 'value'],
        key: 'status',
        ellipsis: true,
        render: (status) => (
            <Tag color={getStatusColor(status)}>
                {status || 'N/A'}
            </Tag>
        ),
    },
    {
        title: 'Priority',
        dataIndex: ['priority_data', 'value'],
        key: 'priority',
        ellipsis: true,
        render: (priority) => (
            <Tag color={getPriorityColor(priority)}>
                {priority || 'N/A'}
            </Tag>
        ),
    },
    {
        title: 'Reporter',
        dataIndex: ['reporter_data', 'name'],
        key: 'reporter',
        ellipsis: true,
        render: (reporter) => reporter || 'N/A',
        },
    {
        title: 'Blocked By',
        dataIndex: ['blocked_by_data', 'name'],
        key: 'blocked_by',
        ellipsis: true,
        render: (blocked_by) => blocked_by || 'N/A',
        },
    {
        title: 'Time Spent',
        dataIndex: 'total_time_spent',
        key: 'total_time_spent',
        ellipsis: true,
        render: (total_time_spent) => total_time_spent || 'N/A',
      },
    {
        title: 'Reopen Count',
        dataIndex: 'reopen_count',
        key: 'reopen_count',
        ellipsis: true,
        },
    {
        title: 'Due Date',
        dataIndex: 'due_date',
        key: 'due_date',
        ellipsis: true,
        render: (due_date) => due_date || 'N/A',
       }
  ];

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ margin: '20px' }}>
      <Spin spinning={loading}>
      <div className='container-home' style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', overflow: 'auto', height:"40vh"}}>
      <div className='filter-container' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 style={{ textAlign: 'center', color: '#31A6C7', paddingLeft: '5px'}}>My Task</h2>
        <div>
          <RangePicker
            allowClear={false}
            style={{ marginRight: '10px' }}
            value={[startDate, endDate]}
            className="range-picker"
            format={"DD/MM/YYYY"}
            onChange={onChangeDateRange}
          />
            <Select
                style={{ width: 130, marginRight: '10px' }}
                onChange={(e) => {
                    filterData('period', e);
                    if (e === undefined) {
                        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
                        setEndDate(dayjs());
                    } else {
                        setStartDate(); setEndDate();
                    }
                }}
                placeholder="Period"
                value={filterKeys?.period}
                optionFilterProp={"children"}
                showSearch={true}
                allowClear={true}
            >
                {PERIOD_CHOICES?.map((option) => (
                    <Select.Option key={option.key} value={option.key} label={option.name}>
                        {option.name}
                    </Select.Option>
                ))}
            </Select>
          <Input style={{ width: 100, marginRight: '10px' }} placeholder="Task Name" value={filterKeys?.name__icontains} onChange={(e) => filterData('name__icontains', e.target.value)}/>
          <Input style={{ width: 100, marginRight: '10px' }} placeholder="Task Id" value={filterKeys?.value__icontains} onChange={(e) => filterData('task_id__icontains', e.target.value)}/>
          <Select
            style={{ width: 150, marginRight: '10px' }}
            value={selectedProject}
            onChange={(value) => {
                setSelectedProject(value);
                filterMultipleData('project', value);
            }}
            placeholder="Project"
            optionFilterProp={"children"}
            showSearch={true}
            allowClear={true}
            mode={"multiple"}
          >
            {projectList?.map((option) => (
                            <Select.Option
                                label={option.name}
                                key={option.id}
                                value={option.id}
                            >
                                {option.name}
                            </Select.Option>
                        ))}
          </Select>
          <Select
            style={{ width: 100, marginRight: '10px' }}
            value={selectedStatus}
            onChange={(value) => {
                setSelectedStatus(value);
                filterMultipleData('status', value);
            }}
            placeholder="Status"
            optionFilterProp={"children"}
            showSearch={true}
            allowClear={true}
            mode={"multiple"}
          >
            {statusList?.map((option) => (
                            <Select.Option
                                label={option.value}
                                key={option.id}
                                value={option.id}
                            >
                                {option.value}
                            </Select.Option>
                        ))}
          </Select>
          <Select
            style={{ width: 100, marginRight: '10px' }}
            value={selectedPriority}
            onChange={(value) => {
                setSelectedPriority(value);
                filterMultipleData('priority', value);
            }}
            placeholder="Priority"
            optionFilterProp={"children"}
            showSearch={true}
            allowClear={true}
            mode={"multiple"}
          >
            {priorityList?.map((option) => (
                            <Select.Option
                                label={option.value}
                                key={option.id}
                                value={option.id}
                            >
                                {option.value}
                            </Select.Option>
                        ))}
          </Select>
          <Select
            style={{ width: 100, marginRight: '10px' }}
            value={selectedType}
            onChange={(value) => {
                setSelectedType(value);
                filterMultipleData('type', value);
            }}
            placeholder="Type"
            optionFilterProp={"children"}
            showSearch={true}
            allowClear={true}
            mode={"multiple"}
          >
            {typeList?.map((option) => (
                            <Select.Option
                                label={option.value}
                                key={option.id}
                                value={option.id}
                            >
                                {option.value}
                            </Select.Option>
                        ))}
          </Select>
          <Button type="primary" onClick={handleReset} icon={<ReloadOutlined />} style={{ marginLeft: '10px' }}></Button>
          </div>
        </div>
          <Table
            dataSource={TaskData}
            columns={columns}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            pagination={false}
            bordered
            size="middle"
            scroll={{ x: 'max-content' }}
          />
          <CustomPagination
            nextPage={nextPage}
            count={count}
            currentPage={currentPage}
            loadData={getTaskData}
          />
        </div>
        <Modal
            title={
                <div style={{ fontSize: '1.5rem', fontWeight: 'bold', paddingLeft: 10 }}>
                    {selectedRowData?.task_id + ' : ' + selectedRowData?.name}
                </div>
            }
          visible={isModalVisible}
          onCancel={handleModalCancel}
          width={"40%"}
          footer={[
            <Button key="close" onClick={handleModalCancel}>
              Close
            </Button>,
          ]}
        >
          {selectedRowData && (
            <Row style={{ padding: "8px 8px" }}>
              <Col
                span={12}
                style={{ cursor: "pointer" }}
              >
                <RenderTaskDetail
                  label={"Project"}
                  value={
                    selectedRowData?.project_data?.name
                      ? selectedRowData.project_data?.name
                      : "--"
                  }
                  icon={<GoldOutlined />}
                />
                <RenderTaskDetail
                  label={"Time Spent"}
                  value={
                    selectedRowData?.total_time_spent
                      ? selectedRowData?.total_time_spent
                      : "--"
                  }
                  icon={<ClockCircleOutlined />}
                />
              </Col>
              <Col span={12}>
                  <Form initialValues={{status:selectedRowData?.status_data?.id}} colon={false}>
                      <Form.Item name={"status"} label={<Typography
                          style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem"}}>
                          Task Status:</Typography>}>
                          <Select
                              style={{ width: 150, marginLeft: '10px', color: getStatusColor(selectedRowData?.status_data?.value)}}
                              onChange={(value) => handleStatusChange(value)}
                              placeholder="Status"
                              optionFilterProp={"children"}
                              showSearch={true}
                          >
                              {statusList?.map((option) => (
                                  <Select.Option
                                      label={option.value}
                                      key={option.id}
                                      value={option.id}
                                  >
                                      <Tag style={{ fontWeight: "bold", fontSize:"1.1rem" }} color={getStatusColor(option.value)} >{option.value}</Tag>
                                  </Select.Option>
                              ))}
                          </Select>
                      </Form.Item>
                  </Form>
              </Col>
              <Col
                span={12}
                style={{ cursor: "pointer" }}
              >
                <RenderTaskDetail
                  label={"Label"}
                  value={
                    selectedRowData?.label_data?.value
                      ? selectedRowData.label_data?.value
                      : "--"
                  }
                  icon={<CalendarOutlined />}
                />
                <RenderTaskDetail
                  label={"Type"}
                  value={
                    selectedRowData?.type_data?.value
                      ? selectedRowData.type_data?.value
                      : "--"
                  }
                  icon={<CalendarOutlined />}
                />
                </Col>
              <Col span={12}>
                  <Typography style={{ fontWeight: "650", cursor: "pointer", fontSize:"1.3rem", paddingTop: 5 }}>
                        Task Priority: <Tag style={{ fontWeight: "bold", fontSize:"1.3rem" }} color={getPriorityColor(selectedRowData?.priority_data?.value)}>{selectedRowData?.priority_data?.value ? selectedRowData?.priority_data?.value : "--"}</Tag>
                  </Typography>
              </Col>
              <Col
                span={12}
                style={{ cursor: "pointer" }}
              >
                <RenderTaskDetail
                  label={"Assignee"}
                  value={
                    selectedRowData?.assigned_to_data?.name
                      ? selectedRowData?.assigned_to_data?.name
                      : "--"
                  }
                  icon={<TeamOutlined />}
                  valueSize={14}
                />
                <RenderTaskDetail
                  label={"Reporter"}
                  value={
                    selectedRowData?.reporter_data?.name
                      ? selectedRowData.reporter_data?.name
                      : "--"
                  }
                  icon={<UserOutlined />}
                />
                </Col>
                  <Col span={12}>
                      <Typography style={{ fontWeight: "650", cursor: "pointer", fontSize:"1.3rem" }}>
                        Due Date: <Tag style={{ fontWeight: "bold", fontSize:"1.3rem"  }}>{selectedRowData?.due_date ? selectedRowData?.due_date : "--"}</Tag>
                      </Typography>
                      <Typography style={{ fontWeight: "650", cursor: "pointer", fontSize:"1.1rem" }}>
                          Reopen Count: <Tag style={{ fontWeight: "bold", fontSize:"1.0rem" }}>{selectedRowData?.reopen_count ? selectedRowData.reopen_count : "0"}</Tag>
                      </Typography>
                  </Col>
              <Col
                span={12}
                style={{ cursor: "pointer" }}
              >
                <RenderTaskDetail
                  label={"Modified By"}
                  value={
                    selectedRowData?.modified_by_data?.name
                      ? selectedRowData.modified_by_data.name
                      : "--"
                  }
                  icon={<TeamOutlined />}
                  valueSize={14}
                />
                </Col>
                <Col span={12}>
                    <Typography style={{ fontWeight: "650", cursor: "pointer", fontSize:"1.3rem" }}>
                        Blocked By: <Tag style={{ fontWeight: "bold", fontSize:"1.3rem"  }}>{selectedRowData?.blocked_by_data?.name ? selectedRowData?.blocked_by_data?.name : "--"}</Tag>
                    </Typography>
                </Col>
                <Col span={24}>
                <RenderFullWidthDetail
                  label={"Comment"}
                  value={
                    selectedRowData?.comment
                      ? selectedRowData.comment
                      : "--"
                  }
                  icon={<MessageOutlined />}
                  valueSize={14}
                />
                </Col>
                <Col span={24}>
                    <Typography style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        fontWeight: '650',
                        cursor: 'pointer'
                    }}>
                        <span style={{marginRight: '8px'}}><MessageOutlined/></span>
                        <span style={{
                            fontWeight: "650",
                            fontSize: "1.0rem",
                            paddingLeft: 8,
                            whiteSpace: 'nowrap'
                        }}>Description:</span>
                        <ReactQuill
                            readOnly
                            value={selectedRowData?.description ? selectedRowData.description : "--"}
                            style={{height: 'auto', paddingLeft: 10}}
                            className="react-quill-view"
                            modules={{toolbar: false}}
                        />
                    </Typography>
                </Col>
                <Col span={24}>
                    <RenderFullWidthDetail
                        label={"Documents"}
                        value={
                            selectedRowData?.documents && selectedRowData.documents.length > 0 ? (
                                selectedRowData.documents.map((document, index) => (
                                    <div key={index}>
                                        <p>
                                            <strong>{index + 1}.</strong>&nbsp;
                                            <a href={document.file_path} target="_blank" rel="noopener noreferrer">
                                                {document.file_label}
                                            </a>
                                        </p>
                                    </div>
                                ))
                            ) : (
                                "--"
                            )
                        }
                        icon={<CalendarOutlined />}
                    />
                </Col>
            </Row>
          )}
        </Modal>
      </Spin>
    </div>
  );
};

export default ViewMyTask;

function RenderTaskDetail(props) {
  return (
    <Row style={{ paddingBottom:"5px"}}>
      <Col span={props.iconSize ? props.iconSize : 2}>{props.icon}</Col>
      <Col
        span={props.labelSize ? props.labelSize : 7}
      >
        <Text style={{ fontWeight: "650", fontSize: "1.0rem" }}>
          {props.label}:{" "}
        </Text>
      </Col>
      <Col
        className={"post-header"}
        span={props.valueSize ? props.valueSize : 15}
      >
        <Text style={{ fontSize: "1.0rem" }}>{props.value}</Text>
      </Col>
    </Row>
  );
}

function RenderFullWidthDetail(props) {
  return (
      <Row style={{ paddingBottom:"5px"}}>
      <Col span={1}>{props.icon}</Col>
      <Col span={4}>
        <Text style={{ fontWeight: "650", fontSize: "1.0rem"}}>
          {props.label}:{" "}
        </Text>
      </Col>
      <Col span={19}>
        <Text style={{ fontSize: "1.0rem"}}>{props.value}</Text>
      </Col>
    </Row>
  );
}
