import React, { useState, useEffect} from "react";
import {ReactComponent as DashboardBg} from "../../../assets/img/Dashboard_Bg.svg";
import {Card, Typography} from "antd";
import dayjs from 'dayjs';
import { handleErrorResponse } from "../../../utils/common";
import { USER_CLONE } from "../../../constants/api";
import {getAPI} from '../../../utils/apiRequest/index';

export const stringTruncate = (string, length, end = "...") => {
    return string?.length < length ? string : string?.substring(0, length) + end
}

const Welcome = (props) => {
    const [UserData, setUserData] = useState([]);


    useEffect(() => {
      getUserData();
    }, []);

    const getUserData = () => {
      let apiParams = {
        pagination: false,
      };

      let successFn = function (result) {
        setUserData(result.user.first_name);
      };

      let errorFn = function (error) {
        handleErrorResponse(error);
      };
      getAPI(USER_CLONE, successFn, errorFn, apiParams);
    };
    // const userInfo = useSelector((state) => state.auth.partner_data)
    return (<Card bodyStyle={{padding:0}} bordered={false} style={{ borderRadius: "15px", borderColor:'#ffff'}}>
            <DashboardBg
                style={{width: "100%", maxWidth: "100%", height: "auto"}}
            />
            <div className={'welcome_c'} >
                <Typography.Text
                    style={{
                        color: "#515F70",
                        fontSize: "14px",
                    }}
                >
                    {dayjs().format("dddd, MMMM D")}
                </Typography.Text>
                <br/>
                <Typography.Text
                    style={{
                        wordWrap: "break-word"
                    }} className="welcome-text">
                    <span style={{color: "#31A6C7"}}>Welcome</span>{" "}
                    {stringTruncate(UserData, 18)}
                </Typography.Text>
                <br/>
            </div>
            <div
                style={{
                    position: "absolute",
                    top: "-28px",
                    left: "20px",
                    height: "115%",
                }}
            >
                <img
                    src="https://partner.plutonic.co.in/dashboard_img.png"
                    alt="Custom Image"
                    className="user-img-dashboard"
                    style={{position: "relative", height: "100%"}}
                />
            </div>
        </Card>

    )
}

export default Welcome