import React, { useState, useEffect } from "react";
import "../../../assets/css/Custom.css";
import { EMPLOYEE_URL, DYNAMIC_URL, TASK_URL, PROJECT_URL } from "../../../constants/api";
import {putAPI, getAPI} from '../../../utils/apiRequest/index';
import {SUCCESS_MSG_TYPE, STATUS} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import {Form, Select, Input, Button, Row, Col, Card, TimePicker, Space, Divider, Checkbox, Modal} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import UploadFile from '../../common/uploadFile';
import dayjs from 'dayjs';
import {useLocation} from "react-router-dom";

const UpdateTaskEmployee = () => {
    const [formData, setFormData] = useState({
      id:"",
      name:"",
      project: null,
      status: "",
      blocked_by: "",
      comment: "",
      time_spend: dayjs("00:00", "HH:mm"),
    });

    const location = useLocation();
    const [form] = Form.useForm();
    const [taskList, setTaskList] = useState([]);
    const [filterKeys, setFilterKeys] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [statusList, setStatus] = useState([]);
    const [blocked_byList, setBlockedBy] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [isDocModalOpen, setIsDocModalOpen] = useState(false);
    const [uploadFileStateMultiple, setUploadFileStateMultiple] = useState({});

    const handleInputChange = (type, values) => {
      setFormData((formData)=>{
        return{...formData, [type]:values ? values : null}
      });
    };

    const handleTaskSelect = (TaskValue, is_update=false) => {
        let selectedTask={};
        if(is_update){
            selectedTask = taskList.filter((task) => task.id === Number(TaskValue))[0];
        }
        else{
            selectedTask = TaskValue;
        }
        if(selectedTask){
        form.setFieldsValue({
            id: selectedTask?.id || "",
            task: selectedTask?.id || "",
            project: selectedTask?.project || "",
            status: selectedTask?.status || "",
            blocked_by: selectedTask?.blocked_by || "",
            comment: selectedTask?.comment || ""
          });
          setFormData(selectedTask);
        }
      };

      const handleStatusSelect = (statusId) => {
        const selectedStatus = statusList.find((status) => status.id === Number(statusId));
        setSelectedStatus(selectedStatus);
      };

    useEffect(() => {
        getProject();
        getStatus();
        getBlockedBy();
        if(location?.state && location?.state?.editData){
            handleTaskSelect(location?.state?.editData);
        }
      }, []);

      useEffect(() => {
        getTask();
      }, [filterKeys]);

      const filterData = (type, value) => {

          setFilterKeys((filterKeys) => {
              return {...filterKeys, [type]: value ? value : undefined};
          });

      };

      const getProject = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          request_size: "low"
        };

        let successFn = function (result) {
          setProjectList(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(PROJECT_URL, successFn, errorFn, apiParams);
      };

      const getTask = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          ...filterKeys,
        };

        let successFn = function (result) {
          setTaskList(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(TASK_URL, successFn, errorFn, apiParams);
      };

      const getStatus = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
          name:STATUS,
        };

        let successFn = function (result) {
          setStatus(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
      };

      const getBlockedBy = () => {
        setLoading(true);
        let apiParams = {
          pagination: false,
        };

        let successFn = function (result) {
          setBlockedBy(result);
          setLoading(false);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
          setLoading(false);
        };
        getAPI(EMPLOYEE_URL, successFn, errorFn, apiParams);
      };


      const handleSubmit = async (values) => {
        const { status, time_spend } = values;
        const formattedTimeSpend = time_spend ? time_spend.format("HH:mm") : undefined;
        let documents = [];
        if (uploadFileStateMultiple['documents']) {
              Object.keys(uploadFileStateMultiple['documents']).map((file, index) => {
                  documents.push({file: uploadFileStateMultiple['documents'][file]?.is_Upload ? uploadFileStateMultiple['documents'][file].file_name : uploadFileStateMultiple['documents'][file].id})
              })
        }
        values.documents = documents
        values.id = formData.id;
        values.name = formData.name;
        values.time_spend = formattedTimeSpend;

        if(status==null){
            emptyFn();
        }
        else{
        setLoading(true);
        let successFn = function (result) {
            const message = values.time_spend ? 'Work Log Added!' : 'Task Status Updated!';
            displayMessage(SUCCESS_MSG_TYPE, message);
            setLoading(false);
            form.resetFields();
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };

        putAPI(TASK_URL, values, successFn, errorFn);
    }
};

const toggleDocModal = (value) => {
    setIsDocModalOpen(value)
}

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};

return (
  <Card style={{padding:24}}>
      <h2 style={{marginBottom: '40px' }}>Update Status / Add Worklog</h2>
      <Form onFinish={handleSubmit} labelCol={{span: 4}} wrapperCol={{span: 20}} form={form}>

      <Row>
          <Col xl={12} lg={12} md={24} sm={24}>
            <Form.Item label={"Project"} name={"project"} rules={[{required: true}]}>
              <Select placeholder={"Project"} onChange={(value) => {handleInputChange("project", value); filterData("project", value); form.resetFields(["task", "status", "comment"]);}} optionFilterProp={"children"} showSearch={true} >
                {projectList.map((project) => (
                  <Select.Option label={project.id} value={project.id}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xl={11} lg={11} md={24} sm={24} offset={1}>
              <Form.Item label={"Task"} name={"task"} rules={[{required: true}]} shouldUpdate={(prevValues, currentValues) => prevValues.project !== currentValues.project} >
                  <Select placeholder={"Task"} onChange={(value) => {handleTaskSelect(value, true)}} disabled={!form.getFieldValue("project")} optionFilterProp={"children"} showSearch={true}>
              {taskList.map((task) => (
                <Select.Option label={task.id} value={task.id}>
                    {task.task_id+ ": " + task.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
            <Form.Item label={"Status"} name={"status"} rules={[{required: true}]}>
                <Select placeholder={"Status"} onChange={(value) => {handleStatusSelect(value)}} optionFilterProp={"children"} showSearch={true}>
                {statusList.map((status) => (
                    <Select.Option label={status.id} value={status.id}>
                    {status.value}
                    </Select.Option>
                ))}
                </Select>
            </Form.Item>
        </Col>

        <Col xl={11} lg={11} md={24} sm={24} offset={1}>
            <Form.Item label={"Blocked By"} name={"blocked_by"} initialValue={
                selectedStatus?.value === 'Blocked' ? form.getFieldValue('blocked_by') : form.setFieldsValue({ blocked_by: undefined })
            }>
                <Select placeholder={"Blocked By"} onChange={(value) => handleInputChange("blocked_by", value)} disabled={selectedStatus?.value !== 'Blocked'}>
                    {blocked_byList.map((blocked_by) => (
                        <Select.Option label={blocked_by.id} value={blocked_by.id} key={blocked_by.id}>
                            {blocked_by.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={24} sm={24}>
            <Form.Item label="Comment" name={"comment"}>
                <Input placeholder="Comment"/>
            </Form.Item>
        </Col>

          <Col xl={11} lg={11} md={24} sm={24} offset={1}>
              <Form.Item label="Documents" name="document" >
                  <Button style={{marginTop: '-30px'}} size={'large'} disabled={false} onClick={() => toggleDocModal(true)}>
                      <PlusOutlined/> Upload
                  </Button>
              </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24}>
              <Form.Item label="Work Log" name="time_spend" >
                  <TimePicker placeholder="Time Spend" format="HH:mm" />
              </Form.Item>
          </Col>

        <Col span={24} style={{textAlign:"center"}}>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
              {loading ? 'Updating...' : 'Update'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
      <Modal open={isDocModalOpen} footer={null} onCancel={() => setIsDocModalOpen(false)} width={700}>
          <Form form={form}>
              <Form.List name={"documents"}>
                  {(fields, { add, remove }) => (
                      <Form.Item label={"Documents"}>
                          {fields.map((field, index) => (
                              <Space
                                  key={index}
                                  style={{ alignItems: "baseline", width: '100%' }}
                                  size={70}
                              >
                                  <Row>
                                      <Col span={23}>
                                          <Form.Item>
                                              <UploadFile
                                                  isMultiple={true}
                                                  keyName={'documents'}
                                                  key={`Upload_document${index}`}
                                                  labelCol={{ span: 10 }}
                                                  {...field}
                                                  name={[field.name, "documents"]}
                                                  extra={uploadFileStateMultiple['documents'] && uploadFileStateMultiple['documents'][`${field.name},documents`] && uploadFileStateMultiple['documents'][`${field.name},documents`]?.is_Upload ? uploadFileStateMultiple['documents'][`${field.name},documents`]?.file_name : null}
                                                  label={`Document ${index + 1}`}
                                                  setState={setUploadFileStateMultiple}
                                                  setLoading={setLoading}
                                              />
                                          </Form.Item>
                                      </Col>
                                      <Col span={1} style={{ paddingLeft: "10px" }}>
                                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                                      </Col>
                                  </Row>
                                  <Divider />
                              </Space>
                          ))}
                          <Form.Item style={{ paddingTop: "30px" }}>
                              <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                              >
                                  Add Document
                              </Button>
                          </Form.Item>
                          <Form.Item {...tailLayout}>
                              <Space>
                                  <Button type={'primary'} className={'theme-color'}  onClick={() => setIsDocModalOpen(false)}>Save</Button>
                              </Space>
                          </Form.Item>
                      </Form.Item>
                  )}
              </Form.List>
          </Form>
      </Modal>
    </Card>
  );
};
export default UpdateTaskEmployee;
