import Login from './components/base/Login';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/modules/Dashboard/dashboard';
import ResetPassword from './components/modules/User/ResetPassword';

function App() {
  return (
    <>
      <Routes>
      <Route exact path="/" element={<Login/>} />
      <Route path="/dashboard/*" element={<Dashboard/>} />
      <Route path={'/password-reset/:token'} element={<ResetPassword/>}/>
      </Routes>
    </>
  );
}

export default App;
