import axios from "axios";
import {displayMessage, handleErrorResponse} from "../common";
import * as lockr from "lockr";
import {AUTH_TOKEN, REFRESH_TOKEN, SUCCESS_MSG_TYPE} from "../../constants/dataKeys";
import {_get} from "../lodashUtils";
import {REFRESH_API, GET_FILE_UPLOAD_SIGNATURE} from "../../constants/api";
import {jwtDecode} from "jwt-decode";


export const validateJWT = (token) => {
    var claims
    try {
        claims = jwtDecode(token);
    }catch (e) {
        clearStorage();
        // window.location.reload();
    }
    const expirationTimeInSeconds = claims?.exp * 1000;
    const now = new Date();
    const isValid = expirationTimeInSeconds >= now.getTime();

    return isValid;
};


export const getAuthToken = async function () {
    let token = lockr.get(AUTH_TOKEN);

    const isValid = validateJWT(token);

    if (token && !isValid) {
        await axios({
            method: "post",
            url: REFRESH_API,
            data: {refresh: lockr.get(REFRESH_TOKEN)},
        })
            .then(function (response) {
                let data = response.data;
                token = data.access
                lockr.set('refresh', data.refresh)
                lockr.set('access', data.access)

            })
            .catch(async function (error) {
                handleErrorResponse(error);
            });
    }


    return token;
};
export const clearStorage = function () {
    localStorage.clear();
};
export const putAPI = async function (
    URL,
    data,
    successFn,
    errorFn,
    headerConfig = {}
) {
    let token = await getAuthToken();
    let authHeaders = {};
    if (token) {
        authHeaders.Authorization = `Bearer ${token}`;
    }
    axios({
        method: "put",
        url: URL,
        data: data,
        headers: {
            ...authHeaders,
            ...headerConfig,
        },
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            if (_get(error, "request.status") === 500) {

                // logErrorToSlackChannel(
                //   JSON.stringify(data),
                //   (errorInfo = { componentStack: _get(error, "request.status") })
                // );
            }
            handleErrorResponse(error);
            errorFn(error.response.data);
        });
};

export const postAPI = async function (
    URL,
    data,
    successFn,
    errorFn,
    headerConfig = {}
) {
    let token = await getAuthToken();
    let authHeaders = {};
    if (token) {
        authHeaders.Authorization = `Bearer ${token}`;
    }
    axios({
        method: "post",
        url: URL,
        data: data,
        headers: {
            ...authHeaders,
            ...headerConfig,
        },
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            if (_get(error, "request.status") === 500) {

                // logErrorToSlackChannel(
                //   JSON.stringify(data),
                //   (errorInfo = { componentStack: _get(error, "request.status") })
                // );
            }
            handleErrorResponse(error);
            errorFn(error.response);
        });
};
export const postOuterAPI = async function (
    URL,
    data,
    successFn,
    errorFn,
    headerConfig = {}
) {
    axios({
        method: "post",
        url: URL,
        data: data,
        headers: {
            ...headerConfig,
        },
    })
        .then(async function (response) {
            let data = response.data;
            await successFn(data);
        })
        .catch(async function (error) {
            handleErrorResponse(error);
            await errorFn(error.response.data);
        });
};

export const getAPI = async function (URL, successFn, errorFn, params = {}) {
    let token = await getAuthToken();
    let authHeaders = {};
    if (token) {
        authHeaders.Authorization = `Bearer ${token}`;
    }
    axios({
        method: "get",
        url: URL,
        headers: {
            ...authHeaders,
        },
        params: params,
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            handleErrorResponse(error);
            errorFn(error);
        });
};

export const getOuterAPI = async function (URL, successFn, errorFn, params = {}) {

    axios({
        method: "get",
        url: URL,
        headers: {
        },
        params: params,
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            handleErrorResponse(error);
            errorFn(error);
        });
};


export const deleteAPI = function (URL, successFn, errorFn) {
    axios({
        method: "delete",
        url: URL,
        headers: {
            // Authorization: 'Bearer ' + getAuthToken()
        },
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            handleErrorResponse(error);
            errorFn();
        });
};

export const postWithOutTokenAPI = function (
    URL,
    data,
    successFn,
    errorFn,
    headerConfig = {}
) {
    axios({
        method: "post",
        url: URL,
        data: data,
        headers: {
            ...headerConfig,
        },
    })
        .then(function (response) {
            let data = response.data;
            successFn(data);
        })
        .catch(function (error) {
            handleErrorResponse(error);
            errorFn(error.response.data);
        });
};

export const putOuterAPI = function (URL, data, successFn, errorFn, headerConfig = {}) {
    axios({
        method: 'put',
        url: URL,
        data,
        headers: {

            ...headerConfig
        },
    }).then(function (response) {
        let data = response.data;
        successFn(data);
    }).catch(function (error) {
        handleErrorResponse(error);
        errorFn(_get(error, 'response.data'));
    });
};

export const handleFileUpload = info => {
    console.log(info);
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
        displayMessage(SUCCESS_MSG_TYPE, `${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
        handleErrorResponse(`${info.file.name} file upload failed.`);
    }
};

let s3SignatureUrl, s3FileUploadType;
export const fileUploadProps = {
    maxCount: 1,
    action: s3SignatureUrl,
    method: "put",
    onChange: handleFileUpload,
    beforeUpload: file => {
        let successFn = function (data) {
            s3SignatureUrl = data.url;
            s3FileUploadType = file.type;

        };
        let errorFn = function () {
        };
        postAPI(
            GET_FILE_UPLOAD_SIGNATURE,
            {image: file.name},
            successFn,
            errorFn
        );
    },
    headers: {
        "Content-Type": s3FileUploadType
    },

};

export const customRequest = (props) => {
    let successFn = function (data) {

        props.onSuccess();
        // that.setState(function (prevState) {
        //     return {
        //         profileData: {
        //             ...prevState.profileData,
        //             image: s3SignatureUrl.split("?")[0]
        //         }
        //     };
        // });
    };
    let errorFn = function () {
    }
    setTimeout(function () {
        putOuterAPI(
            s3SignatureUrl,
            props.file,
            successFn,
            errorFn,
            {
                "Content-Type": props.file.type
            },
            {
                onUploadProgress: event => {
                    props.onProgress({percent: (event.loaded / event.total) * 100});
                }
            }
        );
    }, 500)
}
