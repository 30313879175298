import React, { useState, useEffect } from "react";
import "../../../assets/css/Custom.css";
import { DYNAMIC_URL, EMPLOYEE_URL } from "../../../constants/api";
import {postAPI, getAPI} from '../../../utils/apiRequest/index';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE, DESIGNATION} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import { Form, Select, Input, Button, Row, Col, Card } from 'antd';

const AddEmployee = () => {
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    mobile: "",
    email: "",
  });
  const [form] = Form.useForm();
  const [designationList, setDesignation] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDesignation();
  }, []);

  const getDesignation = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      name:DESIGNATION,
    };

    let successFn = function (result) {
      setDesignation(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
  };

  const handleInputChange = (type, values) => {
    setFormData((formData)=>{
      return{...formData, [type]:values ? values : null}
    });
  };


  const handleSubmit = async (values) => {
    const { name, designation, email, mobile} = values;
    if(name==null || designation==null || email==null || mobile==null){
        emptyFn();
    }
    else{
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'Employee Added Successfully.');
        setLoading(false);
        form.resetFields();
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
        setLoading(false);
    };

    postAPI(EMPLOYEE_URL, values, successFn, errorFn);
    }

  };

  return (
    <Card style={{padding:24}}>
    <h2 style={{marginBottom: '40px' }}>Add Employee</h2>
    <Form onFinish={handleSubmit} labelCol={{ span: 3 }} wrapperCol={{ span: 10 }} form={form}>
    <Row>
    <Col span={24}>
      <Form.Item label="Name" name="name" rules={[{required: true}]}>
        <Input placeholder="Name"/>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label="Mobile Number" name={"mobile"} rules={[{required: true}]}>
        <Input placeholder="Mobile Number"/>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label="Email" name={"email"} rules={[{required: true}]}>
        <Input placeholder="Email"/>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label={"Designation"} name={"designation"} rules={[{required: true}]}>
        <Select placeholder={"Designation"} onChange={(value) => handleInputChange("designation", value)} optionFilterProp={"children"} showSearch={true}>
          {designationList.map((designation) => (
            <Select.Option key={designation.id} value={designation.id} label={designation.value}>
              {designation.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
    </Row>
    <Col span={24} style={{textAlign:"center"}}>
    <Form.Item>
    <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
      {loading ? 'Adding...' : 'Add'}
    </Button>
  </Form.Item>
</Col>
</Form>
</Card>
  );
};
export default AddEmployee;
