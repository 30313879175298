import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { WORK_LOG_URL, PROJECT_URL, TASK_URL,} from "../../../constants/api";
import {getAPI, putAPI} from '../../../utils/apiRequest/index';
import { handleErrorResponse } from "../../../utils/common";
import {Table, Spin, Button, DatePicker, Popconfirm, Divider, Select} from 'antd';
import { ReloadOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import CustomPagination from "../../common/CustomPagination";
import {DEFAULT_PAGE_SIZE, PERIOD_CHOICES} from "../../../constants/dataKeys";

const { RangePicker } = DatePicker;

const ViewWorkLog = () => {
    const [WorkLogData, setWorkLogData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterKeys, setFilterKeys] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').startOf('day'));
    const [endDate, setEndDate] = useState(dayjs());
    const [count, setCount] = useState(1);
    const [nextPage, setnextPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedProject, setSelectedProject] = useState([]);
    const [selectedTask, setSelectedTask] = useState([]);

    const onChangeDateRange = (dates, dateStrings) => {
        setStartDate(dates && dates[0]);
        setEndDate(dates && dates[1]);
        setFilterKeys((prevFilterKeys) => ({
            ...prevFilterKeys,
            period: null,
        }));
    };

    const handleReset = () => {
        setFilterKeys({});
        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
        setEndDate(dayjs());
        setSelectedProject([]);
        setSelectedTask([]);
    };

    const filterData = (type, value) => {
        setFilterKeys((filterKeys) => {
            return {...filterKeys, [type]: value ? value : undefined};
        });
    };

    const filterMultipleData = (type, value) => {
        setFilterKeys((filterKeys) => {
            const joinedValue = value ? value.join(',') : undefined;
            return { ...filterKeys, [type]: joinedValue };
        });
    };

    useEffect(() => {
        getProject();
    }, []);

    useEffect(() => {
        getWorkLogData();
        getTask();
    }, [filterKeys, startDate, endDate]);

    const onDelete = (record) => {
        let reqData = {
            ...record,
            is_active: false,
        };
        let successFn = async function (result) {
            await getWorkLogData();
        };
        let errorFn = function (error) {};
        putAPI(WORK_LOG_URL, reqData, successFn, errorFn);
    };

    const getProject = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            request_size: "low"
        };

        let successFn = function (result) {
            setProjectList(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(PROJECT_URL, successFn, errorFn, apiParams);
    };

    const getTask = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            request_size: "low",
            ...filterKeys,
        };

        let successFn = function (result) {
            setTaskList(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(TASK_URL, successFn, errorFn, apiParams);
    };

    const getWorkLogData = (page=1) => {
        setLoading(true);
        let apiParams = {
            page,
            page_size: DEFAULT_PAGE_SIZE,
            start_date: startDate && dayjs(startDate).format("YYYY-MM-DD"),
            end_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
            ...filterKeys,
        };

        let successFn = function (result) {
            const {next, current, count} = result;
            setWorkLogData(result.results);
            setCount(count);
            setCurrentPage(current);
            setnextPage(next);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(WORK_LOG_URL, successFn, errorFn, apiParams);
    };

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
            width: 50,
        },
        {
            title: 'Project',
            dataIndex: ['project_data', 'name'],
            key: 'project_name',
            ellipsis: true,
            render: (project_name) => project_name || 'N/A',
        },
        {
            title: 'Task Id',
            dataIndex: ['task_data', 'task_id'],
            key: 'task_id',
            ellipsis: true,
            render: (task_id) => task_id || 'N/A',
        },
        {
            title: 'Task Name',
            dataIndex: ['task_data', 'name'],
            key: 'task_name',
            ellipsis: true,
            render: (task_name) => task_name || 'N/A',
        },
        {
            title: 'Work Log',
            dataIndex: 'time_spend',
            key: 'time_spend',
            ellipsis: true,
            render: (time_spend) => time_spend || 'N/A',
        },
        {
            title: 'Time Stamp',
            dataIndex: 'created_at',
            key: 'created_at',
            ellipsis: true,
            render: (created_at) => dayjs(created_at).format('DD-MM-YYYY h:mm A') || 'N/A',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
           <Link to="/dashboard/updateworklog/" state={{editData:record}} >
              <EditOutlined/>
           </Link>
           <Divider type="vertical" />
          <Popconfirm
              title="Are you sure to Delete this?"
              onConfirm={() => onDelete(record)}
              okText="Yes"
              cancelText="No"
          >
            <Button type="link" danger>
              <DeleteOutlined/>
            </Button>
          </Popconfirm>
        </span>
            ),
        }
    ];

    return (
        <div style={{ margin: '20px' }}>
            <Spin spinning={loading}>
                <div className='container-task' style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', overflow: 'auto', maxHeight: '80vh'}}>
                    <div className='filter-container' style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h2 style={{marginBottom: '20px' }}>Work Log</h2>
                        <div>
                            <RangePicker
                                style={{marginRight: '10px'}}
                                allowClear={false}
                                value={[startDate, endDate]}
                                className="range-picker"
                                format={"DD/MM/YYYY"}
                                onChange={onChangeDateRange}
                            />
                            <Select
                                style={{ width: 150, marginRight: '10px' }}
                                onChange={(e) => {
                                    filterData('period', e);
                                    if (e === undefined) {
                                        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
                                        setEndDate(dayjs());
                                    } else {
                                        setStartDate(); setEndDate();
                                    }
                                }}
                                placeholder="Select Period"
                                value={filterKeys?.period}
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                            >
                                {PERIOD_CHOICES?.map((option) => (
                                    <Select.Option key={option.key} value={option.key} label={option.name}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                style={{ width: 150, marginRight: '10px' }}
                                value={selectedProject}
                                onChange={(value) => {
                                    setSelectedProject(value);
                                    filterMultipleData('project', value);
                                }}
                                placeholder="Select Project"
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                                mode={"multiple"}
                            >
                                {projectList?.map((option) => (
                                    <Select.Option
                                        label={option.name}
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Select
                                style={{ width: 150, marginRight: '10px' }}
                                value={selectedTask}
                                onChange={(value) => {
                                    setSelectedTask(value);
                                    filterMultipleData('task', value);
                                }}
                                placeholder="Select Task"
                                optionFilterProp={"children"}
                                showSearch={true}
                                allowClear={true}
                                mode={"multiple"}
                            >
                                {taskList.map((task_d) => (
                                    <Select.Option label={task_d.id} key={task_d.id} value={task_d.id}>
                                        {task_d.task_id}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Button type="primary" onClick={handleReset} icon={<ReloadOutlined />} style={{ marginLeft: '10px' }}></Button>
                        </div>
                    </div>
                    <Table
                        dataSource={WorkLogData}
                        columns={columns}
                        pagination={false}
                        bordered
                        size="middle"
                        scroll={{ x: 'max-content' }}
                    />
                    <CustomPagination
                        nextPage={nextPage}
                        count={count}
                        currentPage={currentPage}
                        loadData={getWorkLogData}
                    />
                </div>
            </Spin>
        </div>
    );
};

export default ViewWorkLog;
