import React, { useState, useEffect } from "react";
import "../../../assets/css/Custom.css";
import { TASK_COMMENTS_URL, TASK_URL, PROJECT_URL } from "../../../constants/api";
import {postAPI, getAPI} from '../../../utils/apiRequest/index';
import {SUCCESS_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import {Form, Select, Button, Row, Col, Card, Input} from 'antd';

const AddTaskComment = () => {
    const [form] = Form.useForm();
    const [taskList, setTaskList] = useState([]);
    const [filterKeys, setFilterKeys] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProject();
    }, []);

    useEffect(() => {
        getTask();
    }, [filterKeys]);

    const filterData = (type, value) => {
        setFilterKeys((filterKeys) => {
            return {...filterKeys, [type]: value ? value : undefined};
        });
    };

    const getProject = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            request_size: "low"
        };

        let successFn = function (result) {
            setProjectList(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(PROJECT_URL, successFn, errorFn, apiParams);
    };

    const getTask = () => {
        setLoading(true);
        let apiParams = {
            pagination: false,
            request_size: "low",
            ...filterKeys,
        };

        let successFn = function (result) {
            setTaskList(result);
            setLoading(false);
        };

        let errorFn = function (error) {
            handleErrorResponse(error);
            setLoading(false);
        };
        getAPI(TASK_URL, successFn, errorFn, apiParams);
    };

    const handleSubmit = async (values) => {
        const { task, project, comment } = values;

        if(comment==null || task==null || project==null){
            emptyFn();
        }
        else{
            setLoading(true);
            let successFn = function (result) {
                displayMessage(SUCCESS_MSG_TYPE, 'Task Comment Added!');
                setLoading(false);
                form.resetFields();
            };

            let errorFn = function (error) {
                handleErrorResponse(error);
                setLoading(false);
            };

            postAPI(TASK_COMMENTS_URL, values, successFn, errorFn);
        }
    };

    return (
        <Card style={{padding:24}}>
            <h2 style={{marginBottom: '40px' }}>Add Task Comment</h2>
            <Form onFinish={handleSubmit} labelCol={{span: 3}} wrapperCol={{span: 9}} form={form}>

                <Row>
                    <Col span={24}>
                        <Form.Item label={"Project"} name={"project"} rules={[{required: true}]}>
                            <Select placeholder={"Project"} optionFilterProp={"children"} showSearch={true}
                                    onChange={(value) => {
                                        form.resetFields(["task"]);
                                        filterData("project", value);
                                    }} >
                                {projectList.map((project) => (
                                    <Select.Option label={project.id} value={project.id}>
                                        {project.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={"Task"} name={"task"} rules={[{required: true}]} shouldUpdate={(prevValues, currentValues) => prevValues.project !== currentValues.project}>
                            <Select placeholder={"Task"} onChange={(value) => filterData("task", value)} disabled={!form.getFieldValue("project")} optionFilterProp={"children"} showSearch={true}>
                                {taskList.map((task) => (
                                    <Select.Option label={task.id} value={task.id}>
                                        {task.task_id+ ": " + task.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Comment" name="comment" rules={[{required: true}]}>
                            <Input placeholder="Task Comment"/>
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{textAlign:"center"}}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
                                {loading ? 'Adding...' : 'Add'}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
export default AddTaskComment;
