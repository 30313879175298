import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TASK_URL, PROJECT_URL, EMPLOYEE_URL, DYNAMIC_URL, USER_CLONE } from "../../../constants/api";
import {getAPI, postAPI, putAPI} from '../../../utils/apiRequest/index';
import {displayMessage, emptyFn, handleErrorResponse} from "../../../utils/common";
import {
    getPriorityColor,
    getStatusColor,
    LABEL,
    PERIOD_CHOICES,
    PRIORITY,
    STATUS, SUCCESS_MSG_TYPE,
    TYPE
} from '../../../constants/dataKeys';
import {
    Table,
    Spin,
    Select,
    Button,
    Modal,
    Row,
    Col,
    Typography,
    DatePicker,
    Divider,
    Popconfirm,
    Input,
    Tag, Form
} from 'antd';
import { ReloadOutlined, TeamOutlined, UserOutlined, CalendarOutlined, MessageOutlined, GoldOutlined, ClockCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomPagination from "../../common/CustomPagination";
import {DEFAULT_PAGE_SIZE} from "../../../constants/dataKeys";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const ViewTask = () => {
  const [TaskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterKeys, setFilterKeys] = useState({});
  const [UserData, setUserData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [labelList, setLabel] = useState([]);
  const [typeList, setType] = useState([]);
  const [statusList, setStatus] = useState([]);
  const [priorityList, setPriority] = useState([]);
  const [employeeList, setEmployee] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [count, setCount] = useState(1);
  const [nextPage, setnextPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedReporter, setSelectedReporter] = useState([]);
  const [form] = Form.useForm();

  const updateInitialValues = () => {
        form.setFieldsValue({
            status: selectedRowData?.status_data?.id,
        });
  };

  useEffect(() => {
        updateInitialValues();
  }, [selectedRowData]);

  useEffect(() => {
    getProject();
    getType();
    getStatus();
    getPriority();
    getLabel();
    getEmployee();
    getUserData();
  }, []);

  const onChangeDateRange = (dates, dateStrings) => {
    setStartDate(dates && dates[0]);
    setEndDate(dates && dates[1]);
      setFilterKeys((prevFilterKeys) => ({
          ...prevFilterKeys,
          period: null,
      }));
  };

  const handleReset = () => {
    setFilterKeys({});
    setStartDate(dayjs().subtract(7, 'day').startOf('day'));
    setEndDate(dayjs());
    setSelectedProject([]);
    setSelectedPriority([]);
    setSelectedType([]);
    setSelectedLabel([]);
    setSelectedStatus([]);
    setSelectedReporter([]);
    setSelectedAssignedTo([]);
  };

  useEffect(() => {
    getTaskData();
  }, [filterKeys, startDate, endDate]);

  const filterData = (type, value) => {

    setFilterKeys((filterKeys) => {
        return {...filterKeys, [type]: value ? value : undefined};
    });

};
    const filterMultipleData = (type, value) => {
        setFilterKeys((filterKeys) => {
            const joinedValue = value ? value.join(',') : undefined;
            return { ...filterKeys, [type]: joinedValue };
        });
    };

  const onDelete = (record, e) => {
    e.stopPropagation();
    let reqData = {
      ...record,
      is_active: false,
    };
    let successFn = async function (result) {
      await getTaskData();
    };
    let errorFn = function (error) {};
    postAPI(TASK_URL, reqData, successFn, errorFn);
  };

  const getUserData = () => {
        let apiParams = {
          pagination: false,
        };

        let successFn = function (result) {
          setUserData(result.user);
        };

        let errorFn = function (error) {
          handleErrorResponse(error);
        };
        getAPI(USER_CLONE, successFn, errorFn, apiParams);
  };

const getProject = () => {
  setLoading(true);
  let apiParams = {
    pagination: false,
    request_size: "low"
  };

  let successFn = function (result) {
    setProjectList(result);
    setLoading(false);
  };

  let errorFn = function (error) {
    handleErrorResponse(error);
    setLoading(false);
  };
  getAPI(PROJECT_URL, successFn, errorFn, apiParams);
};

const getStatus = () => {
  setLoading(true);
  let apiParams = {
    pagination: false,
    name:STATUS,
  };

  let successFn = function (result) {
    setStatus(result);
    setLoading(false);
  };

  let errorFn = function (error) {
    handleErrorResponse(error);
    setLoading(false);
  };
  getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
};

const getPriority = () => {
  setLoading(true);
  let apiParams = {
    pagination: false,
    name:PRIORITY,
  };

  let successFn = function (result) {
    setPriority(result);
    setLoading(false);
  };

  let errorFn = function (error) {
    handleErrorResponse(error);
    setLoading(false);
  };
  getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
};

const getLabel = () => {
  setLoading(true);
  let apiParams = {
    pagination: false,
    name:LABEL,
  };

  let successFn = function (result) {
    setLabel(result);
    setLoading(false);
  };

  let errorFn = function (error) {
    handleErrorResponse(error);
    setLoading(false);
  };
  getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
};

const getEmployee = () => {
  setLoading(true);
  let apiParams = {
    pagination: false,
    request_size: "low"
  };

  let successFn = function (result) {
    setEmployee(result);
    setLoading(false);
  };

  let errorFn = function (error) {
    handleErrorResponse(error);
    setLoading(false);
  };
  getAPI(EMPLOYEE_URL, successFn, errorFn, apiParams);
};

const getType = () => {
  setLoading(true);
  let apiParams = {
    pagination: false,
    name:TYPE,
  };

  let successFn = function (result) {
    setType(result);
    setLoading(false);
  };

  let errorFn = function (error) {
    handleErrorResponse(error);
    setLoading(false);
  };
  getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
};

  const getTaskData = (page=1) => {
    setLoading(true);
    let apiParams = {
      page,
      page_size: DEFAULT_PAGE_SIZE,
      start_date: startDate && dayjs(startDate).format("YYYY-MM-DD"),
      end_date: endDate && dayjs(endDate).format("YYYY-MM-DD"),
      ...filterKeys,
    };

    let successFn = function (result) {
      const {next, current, count} = result;
      setTaskData(result.results);
      setCount(count);
      setCurrentPage(current);
      setnextPage(next);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(TASK_URL, successFn, errorFn, apiParams);
  };

    const handleStatusChange = async (values) => {
        const statusData = {
            "status": values,
            "id": selectedRowData?.id,
            "project": selectedRowData?.project,
            "name": selectedRowData?.name
        }
        if (statusData.status == null) {
            emptyFn();
        } else {
            setLoading(true);
            let successFn = function (result) {
                const message = 'Task Status Updated!';
                displayMessage(SUCCESS_MSG_TYPE, message);
                setLoading(false);
                setIsModalVisible(false);
                getTaskData();
            };
            let errorFn = function (error) {
                handleErrorResponse(error);
                setLoading(false);
            };
            putAPI(TASK_URL, statusData, successFn, errorFn);
        }
    };

  const columns = [
    {
        title: 'S.No',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (text, record, index) => index + 1,
        width: 50,
    },
    {
        title: 'Project',
        dataIndex: ['project_data', 'name'],
        key: 'project',
        ellipsis: true,
        render: (project) => project || 'N/A',
      },
      {
        title: 'Task Id',
        dataIndex: 'task_id',
        key: 'task_id',
        ellipsis: true,
        render: (task_id) => task_id || 'N/A',
    },
    {
        title: 'Title',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        render: (name) => name || 'N/A',
    },
    {
        title: 'Label',
        dataIndex: ['label_data', 'value'],
        key: 'label',
        ellipsis: true,
        render: (label) => label || 'N/A',
        },
    {
        title: 'Type',
        dataIndex: ['type_data', 'value'],
        key: 'type',
        ellipsis: true,
        render: (type) => type || 'N/A',
        },
    {
        title: 'Status',
        dataIndex: ['status_data', 'value'],
        key: 'status',
        ellipsis: true,
        render: (status) => (
            <Tag color={getStatusColor(status)}>
                {status || 'N/A'}
            </Tag>
        ),
        },
    {
        title: 'Priority',
        dataIndex: ['priority_data', 'value'],
        key: 'priority',
        ellipsis: true,
        render: (priority) => (
            <Tag color={getPriorityColor(priority)}>
                {priority || 'N/A'}
            </Tag>
        ),
        },
    {
        title: 'Reporter',
        dataIndex: ['reporter_data', 'name'],
        key: 'reporter',
        ellipsis: true,
        render: (reporter) => reporter || 'N/A',
        },
    {
        title: 'Assigned To',
        dataIndex: ['assigned_to_data', 'name'],
        key: 'assigned_to',
        ellipsis: true,
        render: (assigned_to) => assigned_to || 'N/A',
        },
    {
        title: 'Blocked By',
        dataIndex: ['blocked_by_data', 'name'],
        key: 'blocked_by',
        ellipsis: true,
        render: (blocked_by) => blocked_by || 'N/A',
        },
    {
        title: 'Time Spent',
        dataIndex: 'total_time_spent',
        key: 'total_time_spent',
        ellipsis: true,
        render: (total_time_spent) => total_time_spent || 'N/A',
      },
    {
        title: 'Reopen Count',
        dataIndex: 'reopen_count',
        key: 'reopen_count',
        ellipsis: true,
        },
    {
        title: 'Due Date',
        dataIndex: 'due_date',
        key: 'due_date',
        ellipsis: true,
        render: (due_date) => due_date || 'N/A',
       },
      {
          title: 'WorkLog',
          key: 'work_log',
          className: 'action-column',
          render: (text, record) => (
              <span>
        <Link to="/dashboard/updatetaskstatus/" state={{editData:record}} style={{ color: 'green', display: 'flex', justifyContent: 'center' }} >
          <ClockCircleOutlined/>
        </Link>
      </span>
          ),
      },
   {
    title: 'Action',
    key: 'action',
    className: 'action-column',
    render: (text, record) => (
      <span>
        <Link to="/dashboard/updatetask/" state={{editData:record}} >
          <EditOutlined/>
        </Link>
        <Divider type="vertical" />
        <Popconfirm
              title="Are you sure to Delete this?"
              onConfirm={(e) => onDelete(record, e)}
              onCancel={(e) => e.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
          <Button type="link" danger><DeleteOutlined/></Button>
        </Popconfirm>
      </span>
    ),
  },
  ];

  const handleRowClick = (record, e) => {
      if (!e.target.closest('.action-column') && !e.target.classList.contains('action-column')) {
        setSelectedRowData(record);
        setIsModalVisible(true);
      }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ margin: '20px' }}>
        <Spin spinning={loading}>
            <div className='container-task' style={{
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                overflow: 'auto',
                maxHeight: '80vh'
            }}>
                <div className='filter-container' style={{
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <h2 style={{marginBottom: '20px'}}>Task History</h2>
                    <div>
                        <RangePicker
                            style={{marginRight: '10px', width: 180,}}
                            allowClear={false}
                            value={[startDate, endDate]}
                            className="range-picker"
                            format={"DD/MM/YYYY"}
                            onChange={onChangeDateRange}
                        />
                        <Select
                            style={{width: 120, marginRight: '10px'}}
                            onChange={(e) => {
                                filterData('period', e);
                                if (e === undefined) {
                                    setStartDate(dayjs().subtract(7, 'day').startOf('day'));
                                    setEndDate(dayjs());
                                } else {
                                    setStartDate(); setEndDate();
                                }
                            }}
                            placeholder="Period"
                            value={filterKeys?.period}
                            optionFilterProp={"children"}
                            showSearch={true}
                            allowClear={true}
                        >
                            {PERIOD_CHOICES?.map((option) => (
                                <Select.Option key={option.key} value={option.key} label={option.name}>
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <Input style={{width: 100, marginRight: '10px'}} placeholder="Name"
                               value={filterKeys?.name__icontains}
                               onChange={(e) => filterData('name__icontains', e.target.value)}/>
                        <Input style={{width: 100, marginRight: '10px'}} placeholder="Task Id"
                               value={filterKeys?.value__icontains}
                               onChange={(e) => filterData('task_id__icontains', e.target.value)}/>
                        <Select
                            style={{width: 100, marginRight: '10px'}}
                            value={selectedProject}
                            onChange={(value) => {
                                setSelectedProject(value);
                                filterMultipleData('project', value);
                            }}
                            placeholder="Project"
                            optionFilterProp={"children"}
                            showSearch={true}
                            allowClear={true}
                            mode={"multiple"}
                        >
                            {projectList?.map((option) => (
                                <Select.Option
                                    label={option.name}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <Select
                            style={{width: 100, marginRight: '10px'}}
                            value={selectedStatus}
                            onChange={(value) => {
                                setSelectedStatus(value);
                                filterMultipleData('status', value);
                            }}
                            placeholder="Status"
                            optionFilterProp={"children"}
                            showSearch={true}
                            allowClear={true}
                            mode={"multiple"}
                        >
                            {statusList?.map((option) => (
                                <Select.Option
                                    label={option.value}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.value}
                                </Select.Option>
                            ))}
                        </Select>
                        <Select
                            style={{width: 100, marginRight: '10px'}}
                            value={selectedPriority}
                            onChange={(value) => {
                                setSelectedPriority(value);
                                filterMultipleData('priority', value);
                            }}
                            placeholder="Priority"
                            optionFilterProp={"children"}
                            showSearch={true}
                            allowClear={true}
                            mode={"multiple"}
                        >
                            {priorityList?.map((option) => (
                                <Select.Option
                                    label={option.value}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.value}
                                </Select.Option>
                            ))}
                        </Select>
                        <Select
                            style={{width: 100, marginRight: '10px'}}
                            value={selectedType}
                            onChange={(value) => {
                                setSelectedType(value);
                                filterMultipleData('type', value);
                            }}
                            placeholder="Type"
                            optionFilterProp={"children"}
                            showSearch={true}
                            allowClear={true}
                            mode={"multiple"}
                        >
                            {typeList?.map((option) => (
                                <Select.Option
                                    label={option.value}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.value}
                                </Select.Option>
                            ))}
                        </Select>
                        <Select
                            style={{width: 100, marginRight: '10px'}}
                            value={selectedLabel}
                            onChange={(value) => {
                                setSelectedLabel(value);
                                filterMultipleData('label', value);
                            }}
                            placeholder="Label"
                            optionFilterProp={"children"}
                            showSearch={true}
                            allowClear={true}
                            mode={"multiple"}
                        >
                            {labelList?.map((option) => (
                                <Select.Option
                                    label={option.value}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.value}
                                </Select.Option>
                            ))}
                        </Select>
                        <Select
                            style={{width: 110, marginRight: '10px'}}
                            value={selectedReporter}
                            onChange={(value) => {
                                setSelectedReporter(value);
                                filterMultipleData('reporter', value);
                            }}
                            placeholder="Reporter"
                            optionFilterProp={"children"}
                            showSearch={true}
                            allowClear={true}
                            mode={"multiple"}
                        >
                            {employeeList?.map((option) => (
                                <Select.Option
                                    label={option.name}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <Select
                            style={{width: 110, marginRight: '10px'}}
                            value={selectedAssignedTo}
                            onChange={(value) => {
                                setSelectedAssignedTo(value);
                                filterMultipleData('assigned_to', value);
                            }}
                            placeholder="Assigned To"
                            optionFilterProp={"children"}
                            showSearch={true}
                            allowClear={true}
                            mode={"multiple"}
                        >
                            {employeeList?.map((option) => (
                                <Select.Option
                                    label={option.name}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <Button type="primary" onClick={handleReset} icon={<ReloadOutlined/>}
                                style={{marginLeft: '10px'}}></Button>
                    </div>
                </div>
                <Table
                    dataSource={TaskData}
                    columns={UserData?.is_superuser ? columns : columns.filter(col => col.key !== 'action')}
                    onRow={(record, rowIndex) => ({
                        onClick: (e) => handleRowClick(record, e),
                    })}
                    pagination={false}
                    bordered
                    size="middle"
                    scroll={{x: 'max-content'}}
                />
                <CustomPagination
                    nextPage={nextPage}
                    count={count}
                    currentPage={currentPage}
                    loadData={getTaskData}
                />
            </div>
            <Modal
                title={
                    <div style={{ fontSize: '1.5rem', fontWeight: 'bold', paddingLeft: 10 }}>
                        {selectedRowData?.task_id + ' : ' + selectedRowData?.name}
                    </div>
                }
                visible={isModalVisible}
                onCancel={handleModalCancel}
                width={"40%"}
                footer={[
                <Button key="close" onClick={handleModalCancel}>
                    Close
                </Button>,
            ]}
                >
                {selectedRowData && (
                    <Row style={{padding: "8px 8px"}}>
                        <Col
                            span={12}
                            style={{cursor: "pointer"}}
                        >
                            <RenderTaskDetail
                                label={"Project"}
                                value={
                                    selectedRowData?.project_data?.name
                                        ? selectedRowData.project_data?.name
                                        : "--"
                                }
                                icon={<GoldOutlined/>}
                            />
                            <RenderTaskDetail
                                label={"Time Spent"}
                                value={
                                    selectedRowData?.total_time_spent
                                        ? selectedRowData?.total_time_spent
                                        : "--"
                                }
                                icon={<ClockCircleOutlined/>}
                            />
                        </Col>
                        <Col span={12}>
                            <Form initialValues={{status:selectedRowData?.status_data?.id}} colon={false}>
                                <Form.Item name={"status"} label={<Typography
                                    style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem"}}>
                                    Task Status:</Typography>}>
                                <Select
                                    style={{ width: 150, marginLeft: '10px', color: getStatusColor(selectedRowData?.status_data?.value)}}
                                    onChange={(value) => handleStatusChange(value)}
                                    placeholder="Status"
                                    optionFilterProp={"children"}
                                    showSearch={true}
                                >
                                    {statusList?.map((option) => (
                                        <Select.Option
                                            label={option.value}
                                            key={option.id}
                                            value={option.id}
                                        >
                                            <Tag style={{ fontWeight: "bold", fontSize:"1.1rem" }} color={getStatusColor(option.value)} >{option.value}</Tag>
                                        </Select.Option>
                                    ))}
                                </Select>
                                    </Form.Item>
                                </Form>
                        </Col>
                        <Col
                            span={12}
                            style={{cursor: "pointer"}}
                        >
                            <RenderTaskDetail
                                label={"Label"}
                                value={
                                    selectedRowData?.label_data?.value
                                        ? selectedRowData.label_data?.value
                                        : "--"
                                }
                                icon={<CalendarOutlined/>}
                            />
                            <RenderTaskDetail
                                label={"Type"}
                                value={
                                    selectedRowData?.type_data?.value
                                        ? selectedRowData.type_data?.value
                                        : "--"
                                }
                                icon={<CalendarOutlined/>}
                            />
                        </Col>
                        <Col span={12}>
                            <Typography
                                style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem", paddingTop: 5}}>
                                Task Priority: <Tag style={{fontWeight: "bold", fontSize: "1.3rem"}}
                                                    color={getPriorityColor(selectedRowData?.priority_data?.value)}>{selectedRowData?.priority_data?.value ? selectedRowData?.priority_data?.value : "--"}</Tag>
                            </Typography>
                        </Col>
                        <Col
                            span={12}
                            style={{cursor: "pointer"}}
                        >
                            <RenderTaskDetail
                                label={"Assignee"}
                                value={
                                    selectedRowData?.assigned_to_data?.name
                                        ? selectedRowData?.assigned_to_data?.name
                                        : "--"
                                }
                                icon={<TeamOutlined/>}
                                valueSize={14}
                            />
                            <RenderTaskDetail
                                label={"Reporter"}
                                value={
                                    selectedRowData?.reporter_data?.name
                                        ? selectedRowData.reporter_data?.name
                                        : "--"
                                }
                                icon={<UserOutlined/>}
                            />
                        </Col>
                        <Col span={12}>
                            <Typography style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem"}}>
                                Due Date: <Tag style={{
                                fontWeight: "bold",
                                fontSize: "1.3rem"
                            }}>{selectedRowData?.due_date ? selectedRowData?.due_date : "--"}</Tag>
                            </Typography>
                            <Typography style={{fontWeight: "650", cursor: "pointer", fontSize: "1.1rem"}}>
                                Reopen Count: <Tag style={{
                                fontWeight: "bold",
                                fontSize: "1.0rem"
                            }}>{selectedRowData?.reopen_count ? selectedRowData.reopen_count : "0"}</Tag>
                            </Typography>
                        </Col>
                        <Col
                            span={12}
                            style={{cursor: "pointer"}}
                        >
                            <RenderTaskDetail
                                label={"Modified By"}
                                value={
                                    selectedRowData?.modified_by_data?.name
                                        ? selectedRowData.modified_by_data.name
                                        : "--"
                                }
                                icon={<TeamOutlined/>}
                                valueSize={14}
                            />
                        </Col>
                        <Col span={12}>
                            <Typography style={{fontWeight: "650", cursor: "pointer", fontSize: "1.3rem"}}>
                                Blocked By: <Tag style={{
                                fontWeight: "bold",
                                fontSize: "1.3rem"
                            }}>{selectedRowData?.blocked_by_data?.name ? selectedRowData?.blocked_by_data?.name : "--"}</Tag>
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <RenderFullWidthDetail
                                label={"Comment"}
                                value={
                                    selectedRowData?.comment
                                        ? selectedRowData.comment
                                        : "--"
                                }
                                icon={<MessageOutlined/>}
                                valueSize={14}
                            />
                        </Col>
                        <Col span={24}>
                            <Typography style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                fontWeight: '650',
                                cursor: 'pointer'
                            }}>
                                <span style={{marginRight: '8px'}}><MessageOutlined/></span>
                                <span style={{
                                    fontWeight: "650",
                                    fontSize: "1.0rem",
                                    paddingLeft: 8,
                                    whiteSpace: 'nowrap'
                                }}>Description:</span>
                                <ReactQuill
                                    readOnly
                                    value={selectedRowData?.description ? selectedRowData.description : "--"}
                                    style={{height: 'auto', paddingLeft: 10}}
                                    className="react-quill-view"
                                    modules={{toolbar: false}}
                                />
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <RenderFullWidthDetail
                                label={"Documents"}
                                value={
                                    selectedRowData?.documents && selectedRowData.documents.length > 0 ? (
                                        selectedRowData.documents.map((document, index) => (
                                            <div key={index}>
                                                <p>
                                                    <strong>{index + 1}.</strong>&nbsp;
                                                    <a href={document.file_path} target="_blank" rel="noopener noreferrer">
                                                        {document.file_label}
                                                    </a>
                                                </p>
                                            </div>
                                        ))
                                    ) : (
                                        "--"
                                    )
                                }
                                icon={<CalendarOutlined />}
                            />
                        </Col>
                    </Row>
                )}
        </Modal>
    </Spin>
</div>
)
    ;
};

export default ViewTask;

function RenderTaskDetail(props) {
    return (
        <Row style={{ paddingBottom:"5px"}}>
            <Col span={props.iconSize ? props.iconSize : 2}>{props.icon}</Col>
            <Col
                span={props.labelSize ? props.labelSize : 7}
            >
                <Text style={{ fontWeight: "650", fontSize: "1.0rem" }}>
                    {props.label}:{" "}
                </Text>
            </Col>
            <Col
                className={"post-header"}
                span={props.valueSize ? props.valueSize : 15}
            >
                <Text style={{ fontSize: "1.0rem" }}>{props.value}</Text>
            </Col>
        </Row>
    );
}

function RenderFullWidthDetail(props) {
    return (
        <Row style={{ paddingBottom:"5px"}}>
            <Col span={1}>{props.icon}</Col>
            <Col span={4}>
                <Text style={{ fontWeight: "650", fontSize: "1.0rem"}}>
                    {props.label}:{" "}
                </Text>
            </Col>
            <Col span={19}>
                <Text style={{ fontSize: "1.0rem"}}>{props.value}</Text>
            </Col>
        </Row>
    );
}
