import React from 'react';
import {Col, Row, Typography} from 'antd';

const {Title, Text} = Typography;

class AuthBase extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            hideNav: true
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({hideNav: window.innerWidth <= 760});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {
        return (
            <Row>
                {this.state.hideNav ? null : <Col md={12} lg={12} xl={12} sm={24} xs={24} style={{minHeight:"100vh", padding:"60px", backgroundSize:"cover", backgroundColor:"#31A6C7" }}>
                    <Row style={{paddingTop: "15vh", paddingLeft: "5vh"}}>
                        <img alt="Plutonic Logo" style={{width: "11em", height:"5em"}} src="https://www.plutonic.co.in/imgs/plutonic_logo.png"/>
                    </Row>
                    <Row style={{marginTop: 40}}>
                        <Col span={1} style={{backgroundColor: "white", height: "18em", maxWidth: "0.36%"}}/>
                        <Col span={18} offset={1}><Title style={{fontSize: "4em", color: "white", fontWeight: "bold", marginTop: 0}}>Welcome to Plutonic Task Management</Title><Text style={{color: "white", fontSize: 14}}>
                            <span>Your gateway to streamlined productivity. Experience seamless task organization and team collaboration through our user-friendly interface, ensuring efficient project management at your fingertips.</span>
                        </Text></Col>
                    </Row>
                </Col>}
                <Col md={12} lg={12} xl={12} sm={24} xs={24} style={{width: "100%", backgroundColor:"#f2f2f3"}}>
                    <div style={{
                        padding: this.state.hideNav ? "1em" : "6em",
                        marginTop: this.state.hideNav ? "2em" : "8em"
                    }}>
                        {this.state.hideNav ?
                            <img alt="Plutonic Logo" style={{width: "11em", height:"5em"}}
                                 src="https://www.plutonic.co.in/imgs/plutonic_logo.png"/> : null}
                        {this.props.children}
                    </div>
                </Col>
            </Row>
        );
    }
}

export default AuthBase;
