import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import {
  PROJECT_URL,
  EMPLOYEE_URL,
  EMPLOYEE_WORK_LOG_REPORT_URL
} from '../../../constants/api';
import { getAPI } from '../../../utils/apiRequest/index';
import { handleErrorResponse } from '../../../utils/common';
import { Table, Spin, Select, Button, DatePicker } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import {PERIOD_CHOICES} from "../../../constants/dataKeys";

const { RangePicker } = DatePicker;

const EmployeeTimeSheet = () => {
  const [WorkLogData, setWorkLogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterKeys, setFilterKeys] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [employeeList, setEmployee] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  useEffect(() => {
    getProject();
    getEmployee();
  }, []);

  const onChangeDateRange = (dates, dateStrings) => {
    setStartDate(dates && dates[0]);
    setEndDate(dates && dates[1]);
    setFilterKeys((prevFilterKeys) => ({
      ...prevFilterKeys,
      period: null,
    }));
  };

  const handleReset = () => {
    setFilterKeys({});
    setStartDate(dayjs().subtract(7, 'day').startOf('day'));
    setEndDate(dayjs());
    setSelectedProject([]);
    setSelectedEmployee([]);
  };

  useEffect(() => {
    getWorkLogData();
  }, [filterKeys, startDate, endDate]);

  const filterData = (type, value) => {
    setFilterKeys((filterKeys) => {
      return { ...filterKeys, [type]: value ? value : undefined };
    });
  };

  const filterMultipleData = (type, value) => {
    setFilterKeys((filterKeys) => {
      const joinedValue = value ? value.join(',') : undefined;
      return { ...filterKeys, [type]: joinedValue };
    });
  };

  const getProject = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      request_size: "low"
    };

    let successFn = function (result) {
      setProjectList(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(PROJECT_URL, successFn, errorFn, apiParams);
  };

  const getEmployee = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      request_size: "low"
    };

    let successFn = function (result) {
      setEmployee(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(EMPLOYEE_URL, successFn, errorFn, apiParams);
  };

  const getWorkLogData = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      start_date: startDate && dayjs(startDate).format('YYYY-MM-DD'),
      end_date: endDate && dayjs(endDate).format('YYYY-MM-DD'),
      is_timesheet: true,
      ...filterKeys,
    };

    let successFn = function (result) {
      const filteredResult = filterKeys.project ?
          result.filter(projectData => filterKeys.project.includes(projectData.project_id))
          : result;
        setWorkLogData(filteredResult);
        setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(EMPLOYEE_WORK_LOG_REPORT_URL, successFn, errorFn, apiParams);
  };

  const renderEmployeeColumns = () => {
    const columns = [
      {
        title: 'Project',
        dataIndex: ['project'],
        key: 'project',
        width: 150
      },
    ];

  if (filterKeys.employee) {
    const filteredEmployees = filterKeys.employee ?
        employeeList.filter(employee => filterKeys.employee.includes(employee.id))
        : [];
    if (filteredEmployees) {
      filteredEmployees.forEach(filteredEmployee => {
        columns.push(generateEmployeeColumn(filteredEmployee));
      });
    }
  } else {
    employeeList.forEach((employee) => {
      columns.push(generateEmployeeColumn(employee));
    });
  }
    return columns;
  };

    const generateEmployeeColumn = (employee) => ({
      title: employee.name,
      key: employee.id,
      render: (item, record) => {
        const worklog = record.employees.find(element => element.employee_id === employee.id)?.worklog || "0h";
        const isWorklogAvailable = worklog !== "0h";

        return (
          <span style={{ color: isWorklogAvailable ? 'green' : 'grey', fontWeight: isWorklogAvailable ? 'bold' : 'normal' }}>
            {worklog}
          </span>
        );
      },
    });

  return (
    <div style={{ margin: '20px' }}>
      <Spin spinning={loading}>
        <div
          className="container-task"
          style={{
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className="filter-container"
            style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <h2 style={{ marginBottom: '20px' }}>Employee Time Sheet</h2>
            <div>
              <RangePicker
                style={{ marginRight: '10px' }}
                allowClear={false}
                value={[startDate, endDate]}
                className="range-picker"
                format={'DD/MM/YYYY'}
                onChange={onChangeDateRange}
              />
              <Select
                style={{ width: 150, marginRight: '10px' }}
                onChange={(e) => {
                  filterData('period', e);
                  if (e === undefined) {
                    setStartDate(dayjs().subtract(7, 'day').startOf('day'));
                    setEndDate(dayjs());
                  } else {
                    setStartDate(); setEndDate();
                  }
                }}
                placeholder="Select Period"
                value={filterKeys?.period}
                optionFilterProp={"children"}
                showSearch={true}
                allowClear={true}
              >
                {PERIOD_CHOICES?.map((option) => (
                    <Select.Option key={option.key} value={option.key} label={option.name}>
                    {option.name}
                    </Select.Option>
                ))}
              </Select>
              <Select
                style={{ width: 150, marginRight: '10px' }}
                value={selectedProject}
                onChange={(value) => {
                  setSelectedProject(value);
                  filterMultipleData('project', value);
                }}
                placeholder="Select Project"
                optionFilterProp={"children"}
                showSearch={true}
                allowClear={true}
                mode={"multiple"}
              >
                {projectList?.map((option) => (
                  <Select.Option label={option.name} key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
              <Select
                style={{ width: 150, marginRight: '10px' }}
                value={selectedEmployee}
                onChange={(value) => {
                  setSelectedEmployee(value);
                  filterMultipleData('employee', value);
                }}
                placeholder="Select Employee"
                optionFilterProp={"children"}
                showSearch={true}
                allowClear={true}
                mode={"multiple"}
              >
                {employeeList?.map((option) => (
                  <Select.Option label={option.name} key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
              <Button type="primary" onClick={handleReset} icon={<ReloadOutlined />} style={{ marginLeft: '10px' }}></Button>
            </div>
          </div>

          <Table
            dataSource={WorkLogData}
            columns={renderEmployeeColumns()}
            pagination={{ pageSize: 15}}
            bordered
            size="middle"
            scroll={{ x: 'max-content',  y: 'calc(80vh - 200px)' }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default EmployeeTimeSheet;
