import React, { useState, useEffect } from "react";
import "../../../assets/css/Custom.css";
import { TASK_COMMENTS_URL } from "../../../constants/api";
import {putAPI} from '../../../utils/apiRequest/index';
import {SUCCESS_MSG_TYPE} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import {Form, Button, Row, Col, Card, Input} from 'antd';
import {useLocation, useNavigate} from "react-router-dom";

const UpdateTaskComment = () => {
    const [formData, setFormData] = useState({
        id:"",
        project: null,
        task: null,
        comment: "",
    });

    const [form] = Form.useForm();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleTaskCommentSelect = (selectedTaskComment, is_update=false) => {
        if(selectedTaskComment){
            form.setFieldsValue({
                id: selectedTaskComment?.id || "",
                project_name: selectedTaskComment?.project_data?.name || "",
                task_name: selectedTaskComment?.task_data?.name || "",
                comment: selectedTaskComment?.comment || "",
            });
            setFormData(selectedTaskComment);
        }
    };

    useEffect(() => {
        if(location?.state && location?.state?.editData){
            handleTaskCommentSelect(location?.state?.editData);
        }
    },[]);
    const handleSubmit = async (values) => {
        const { comment } = values;
        values.id = formData.id;
        values.task = formData.task;
        values.project = formData.project;
        values.time_spend = formData.comment;
        if(comment==null){
            emptyFn();
        }
        else{
            setLoading(true);
            let successFn = function (result) {
                displayMessage(SUCCESS_MSG_TYPE, 'Task Comment Updated!');
                setLoading(false);
                form.resetFields();
                navigate("/dashboard/viewtaskcomment/");
            };

            let errorFn = function (error) {
                handleErrorResponse(error);
                setLoading(false);
            };

            putAPI(TASK_COMMENTS_URL, values, successFn, errorFn);
        }
    };

    return (
        <Card style={{padding:24}}>
            <h2 style={{marginBottom: '40px' }}>Update Task Comment</h2>
            <Form onFinish={handleSubmit} labelCol={{span: 3}} wrapperCol={{span: 9}} form={form}>
                <Row>
                    <Col span={24}>
                        <Form.Item label={"Project"} name={"project_name"} >
                            <Input placeholder="Project" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label={"Task"} name={"task_name"}>
                            <Input placeholder="Task" disabled={true}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Comment" name="comment" rules={[{required: true}]}>
                            <Input placeholder="Task Comment"/>
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{textAlign:"center"}}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
                                {loading ? 'Updating...' : 'Update'}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
export default UpdateTaskComment;
