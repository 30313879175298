import {Button, Input, Typography} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {useEffect, useRef, useState} from 'react';
import {DEFAULT_PAGE_SIZE} from "../../constants/dataKeys";

const CustomPagination = ({
                              count,
                              loadData,
                              nextPage,
                              currentPage,
                              pageSize = DEFAULT_PAGE_SIZE
                          }) => {
    const [newCurrent, setNewCurrent] = useState(currentPage)
    const [input, setInput] = useState(null)
    const [sizeOfPage, setSizeOfPage] = useState(1)
    const inputRef = useRef()


    // const sizeOfPage = parseInt(Math.ceil(count / pageSize));


    useEffect(()=>{
        if (count>pageSize){
            setSizeOfPage(parseInt(Math.ceil(count / pageSize)))
        }else {
            setSizeOfPage(1)
        }

    },[count, pageSize])

    const handleJump = (page) => {
        if (page > sizeOfPage) {
            loadData(sizeOfPage);
        } else if (page < 1) {
            loadData(1);
            setNewCurrent(1)
        } else {
            loadData(page);
        }
    };

    useEffect(() => {
        if (input) {
            handleJump(newCurrent)
        }
    }, [input])

    useEffect(() => {
        setNewCurrent(currentPage)
        inputRef.current.input.value = currentPage

    }, [currentPage])

    const handleInputChange = (e) => {
        setNewCurrent(e.target.value)
        setInput(e.target.value)
    }

    const handleBlur = () => {
        const value = parseInt(newCurrent)
        if (value > sizeOfPage) {
            setNewCurrent(sizeOfPage)
        }
    }


    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button disabled={currentPage === 1} style={{border: 'none', margin: '12px'}}
                    onClick={() => loadData(currentPage - 1)}>
                <LeftOutlined/>
            </Button>
            <Typography.Text>
                <Input
                    type='number'
                    min={1}
                    ref={inputRef}
                    max={sizeOfPage}
                    onBlur={handleBlur}
                    value={newCurrent}
                    onChange={handleInputChange}
                    style={{width: '65px', textAlign: 'center'}}
                />
                of {sizeOfPage}
            </Typography.Text>
            <Button disabled={!nextPage} style={{border: 'none', margin: '12px'}} onClick={() => loadData(nextPage)}>
                <RightOutlined/>
            </Button>
        </div>
    );
};

export default CustomPagination;
