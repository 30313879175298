import {BellFilled, ClockCircleOutlined, ReloadOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {Avatar, Badge, Card, Col, Dropdown, List, Row, Button} from "antd";
import React, {useEffect, useState} from "react";
import {getAPI} from '../../../utils/apiRequest/index';
import {NOTIFICATION_READ_URL, NOTIFICATION_VIEW_URL} from "../../../constants/api";
import dayjs from 'dayjs';
import {handleErrorResponse} from "../../../utils/common";
import {Link} from "react-router-dom";


const NotificationDropdown = () => {
    const [notificationList, setNotificationList] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [openNotification, setOpenNotification] = useState(false);

    const items = notificationList.map((item, index) => ({
        label: `${item.content} - ${item.created_at}`,
        key: item.id?.toString(),
    }));

    useEffect(() => {
        getNotifications();
    }, []);
    const getNotifications = () => {
        let apiParams = {
            page_size: 10,
        };
        const successFn = (result) => {
            if (result.current === 1) {
                setNotificationList(result.results);
            } else {
                setNotificationList((prevState) => [...prevState, ...result.results]);
            }
            setUnreadCount(result.extra?.unread_count);
        };
        const errorFn = (error) => {
            handleErrorResponse(error);
        };
        getAPI(NOTIFICATION_VIEW_URL, successFn, errorFn, apiParams);
    };

    const handleMarkRead = (e) => {
        if (e) {
            return;
        } else if (!e && notificationList.length) {
            let apiParams = {
                id: notificationList[0].id,
                read: true,
            };
            const successFn = (result) => {
                getNotifications()
            };
            const errorFn = (error) => {
                handleErrorResponse(error);
            };

            setOpenNotification(false)
            getAPI(NOTIFICATION_READ_URL, successFn, errorFn, apiParams);

        } else {
            setOpenNotification(false)
        }
    };

    const handleReload = () => {
        getNotifications();
    };

    const contentStyle = {
        backgroundColor: "white",
        borderRadius: '20px',
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
    };

    return (
        <div>
            <Dropdown
                placement="bottomRight"
                open={openNotification}
                arrow={{
                    pointAtCenter: true,
                }}
                overlayStyle={{width: "22vw"}}
                menu={items}
                onOpenChange={(e) => handleMarkRead(e)}
                dropdownRender={(menu) => (
                    <div style={contentStyle}>
                        <Card headStyle={{padding: '8px 2px 8px 18px', fontSize: 18, fontWeight: 600}}
                              bodyStyle={{padding: 8}} title={'Notifications'}
                              extra={
                                  <div>
                                      <Link to="/dashboard/notifications/" style={{paddingRight: '10px'}} onClick={() => setOpenNotification(false)}>
                                          <UnorderedListOutlined />&nbsp;View All
                                      </Link>
                                      <Button type="link" style={{paddingRight: '10px'}} icon={<ReloadOutlined />} onClick={handleReload}></Button>
                                  </div>
                              }
                              bordered={false} style={{border: "none"}}>
                            <Row
                                justify="center"
                                style={{height: "60vh", overflow: "auto"}}
                            >
                                <Col span={24}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={notificationList}
                                        renderItem={(item) => (
                                            <List.Item className="notification-item">
                                                <List.Item.Meta
                                                    avatar={<Avatar icon={<ClockCircleOutlined/>}/>}
                                                    title={item.content}
                                                    description={dayjs(
                                                        item.created_at
                                                    ).format("DD/MM/YYYY h:mm A")}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                )}
                trigger={["click"]}
            >
                <Button onClick={(e) => setOpenNotification(!openNotification)} style={{ border: 'none', background: 'none', padding: 0 }}>
                    <Badge size="small" count={unreadCount}>
                         <BellFilled style={{fontSize: "25px", color: "white"}}/>
                    </Badge>
                </Button>
            </Dropdown>
        </div>
    );
};

export default NotificationDropdown;
