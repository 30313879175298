import React, { useState } from 'react';
import { Button, Form, Space, Spin, Tooltip, Typography, Table, Card} from "antd";
import { displayMessage } from "../../../utils/common";
import { postAPI } from '../../../utils/apiRequest';
import { SUCCESS_MSG_TYPE } from '../../../constants/dataKeys';
import { TASK_BULK_UPLOAD_URL } from '../../../constants/api';
import { REQUIRED_FIELD_MESSAGE } from '../../../constants/message';
import UploadFile from '../../common/uploadFile';

const { Text } = Typography;
const handleBulkUploadErrors = (errors) => {
    if (errors) {
        let errorList = [];
        errors.forEach(function(error, index) {
            if (error.detail) {
                if (Array.isArray(error.detail)) {
                    error?.detail?.forEach(function(item) {
                        errorList.push({
                            position: index + 1,
                            error_msg: item,
                        });
                    });
                } else {
                    errorList.push({
                        position: index + 1,
                        error_msg: error.detail,
                    });
                }
            }
        });
        return errorList;
    }
};

const UploadTask = (props) => {
    const [loading, setLoading] = useState(false);
    const [bulkUploadErrors, setBulkUploadErrors] = useState([]);
    const [uploadFileState, setUploadFileState] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onFinish = (values) => {
        let reqData = {
            report_upload:
            uploadFileState.report_upload.file_name,
            is_company : true,
        };
        setLoading(true);
        let successFn = function (result) {
            setBulkUploadErrors([]);
            displayMessage(SUCCESS_MSG_TYPE, 'Bulk Task Successfully Created.');
            setIsModalOpen(false);
            setUploadFileState({})
            setLoading(false);
        };
        let errorFn = function (error) {
            setLoading(false);
            setBulkUploadErrors(error.data.length ? handleBulkUploadErrors(error.data) : [{id : 1}]);
        };
        postAPI(TASK_BULK_UPLOAD_URL, reqData, successFn, errorFn);
    };

    const handleFileRemove = () => {
        setBulkUploadErrors([]);
    }

    if(uploadFileState.report_upload === {} || undefined){
        setBulkUploadErrors([]);
    }


    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 4, span: 16 },
    };

    const handleCancel = () => {
        handleFileRemove();
        setUploadFileState({})
        setIsModalOpen(false)
    }


    return (
        <Card>
            <h2 style={{marginTop: '-10px'}}>Task Bulk Upload</h2>
            <Spin spinning={loading}>
                <Form
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    {...layout}
                    key={'bulk_task_upload'}
                >
                    <Form.Item label={'Upload Excel'} rules={[{required: true}]}>
                        <Space>
                            <UploadFile
                                key={'report_upload'}
                                name={"report_upload"}
                                colon={false}
                                setLoading={setLoading}
                                setState={setUploadFileState}
                                setBulkUploadErrors={setBulkUploadErrors}
                                uploadFileState={uploadFileState}
                                setUploadFileState={setUploadFileState}
                                extra={uploadFileState?.report_upload?.file_name}
                            />
                            <Tooltip title='Download Sample Format'>
                                <Typography.Link
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    href={`/Bulk_Task_Upload.xlsx`}
                                >
                                    Download Sample Format?
                                </Typography.Link>
                            </Tooltip>
                        </Space>
                    </Form.Item>
                    {bulkUploadErrors.length ?
                        <Form.Item label={' '} colon={false}>
                            <Table columns={[
                                {
                                    title: <Text type='danger'>Row</Text>,
                                    key: 'row',
                                    dataIndex: 'position',
                                },
                                {
                                    title: <Text type='danger'>Error Msg</Text>,
                                    key: 'error_msg',
                                    dataIndex: 'error_msg',
                                },
                            ]} dataSource={bulkUploadErrors} pagination={false}/>
                        </Form.Item> : null}
                    <Form.Item {...tailLayout}>
                        <Space>
                            <Button
                                htmlType='submit'
                                type={'primary'}
                                className={'theme-color'}
                            >
                                Save
                            </Button>
                            <Button onClick={() => handleCancel()}>Cancel</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </Card>
    )
}
export default UploadTask

const validateMessages = {
    required: REQUIRED_FIELD_MESSAGE,
}; 