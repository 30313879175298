import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import "../../../assets/css/Custom.css";
import { DYNAMIC_URL, EMPLOYEE_URL } from "../../../constants/api";
import {postAPI, getAPI} from '../../../utils/apiRequest/index';
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE, DESIGNATION} from '../../../constants/dataKeys';
import {displayMessage, emptyFn, handleErrorResponse} from '../../../utils/common';
import { Form, Select, Input, Button, Row, Col, Card, Checkbox } from 'antd';

const UpdateEmployee = () => {
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    mobile: "",
    email: "",
    id:"",
  });
  const [form] = Form.useForm();
  const [designationList, setDesignation] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    getEmployee();
    getDesignation();
    if(location?.state && location?.state?.editData){
            handleEmployeeSelect(location?.state?.editData);
    }
  }, []);
  

  const getEmployee = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
    };

    let successFn = function (result) {
      setEmployeeList(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(EMPLOYEE_URL, successFn, errorFn, apiParams);
  };

  const handleEmployeeSelect = (EmployeeValue, is_update=false) => {
    let selectedEmployee={};
        if(is_update){
            selectedEmployee = employeeList.filter((Employee) => Employee.id === Number(EmployeeValue))[0];
        }
        else{
            selectedEmployee = EmployeeValue;
        }
    if(selectedEmployee){
    form.setFieldsValue({
      id: selectedEmployee?.id,
      name: selectedEmployee?.name,
      email: selectedEmployee?.user_data?.email,
      mobile: selectedEmployee?.mobile,
      designation: selectedEmployee?.designation,
    });
    setFormData(selectedEmployee);
  }
  };

  const getDesignation = () => {
    setLoading(true);
    let apiParams = {
      pagination: false,
      name:DESIGNATION,
    };

    let successFn = function (result) {
      setDesignation(result);
      setLoading(false);
    };

    let errorFn = function (error) {
      handleErrorResponse(error);
      setLoading(false);
    };
    getAPI(DYNAMIC_URL, successFn, errorFn, apiParams);
  };

  const handleInputChange = (type, values) => {
    setFormData((formData)=>{
      return{...formData, [type]:values ? values : null}
    });
  };


  const handleSubmit = async (values) => {
    const { name, designation, email, mobile} = values;
    values.id=formData.id;
    values.is_active ? (values.is_active = false) : delete values.is_active;
    if(name==null || designation==null || email==null || mobile==null){
        emptyFn();
    }
    else{
    setLoading(true);
    let successFn = function (result) {
        displayMessage(SUCCESS_MSG_TYPE, 'Employee Detail Updated!');
        setLoading(false);
        form.resetFields();
    };

    let errorFn = function (error) {
        Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, error.data.detail));
        setLoading(false);
    };

    postAPI(EMPLOYEE_URL, values, successFn, errorFn);
    }

  };

  return (
    <Card style={{padding:24}}>
    <h2 style={{marginBottom: '40px' }}>Edit Employee</h2>
    <Form onFinish={handleSubmit} labelCol={{ span: 3 }} wrapperCol={{ span: 10 }} form={form}>
    <Row>
    <Col span={24}>
      <Form.Item label="Name" name="name" rules={[{required: true}]}>
        <Select placeholder={"Select Employee"} onChange={(value) => handleEmployeeSelect(value, true)} optionFilterProp={"children"} showSearch={true}>
          {employeeList.map((Employee) => (
            <Select.Option key={Employee.name} value={Employee.id} label={Employee.name}>
              {Employee.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label="Mobile Number" name={"mobile"} rules={[{required: true}]}>
        <Input placeholder="Mobile Number"/>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label="Email" name={"email"} rules={[{required: true}]}>
        <Input placeholder="Email"/>
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item label={"Designation"} name={"designation"} rules={[{required: true}]}>
        <Select placeholder={"Designation"} onChange={(value) => handleInputChange("designation", value)} optionFilterProp={"children"} showSearch={true}>
          {designationList.map((designation) => (
            <Select.Option key={designation.id} value={designation.id} label={designation.value}>
              {designation.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
    <Col xl={24} lg={24} md={24} sm={24}>
        <Form.Item name="is_active" valuePropName="checked" initialValue={false} noStyle>
            <Checkbox>Delete Employee</Checkbox>
        </Form.Item>
    </Col>
    </Row>
    <Col span={24} style={{textAlign:"center"}}>
    <Form.Item>
    <Button type="primary" htmlType="submit" style={{paddingLeft:25, paddingRight:25}}>
      {loading ? 'Updating...' : 'Update'}
    </Button>
  </Form.Item>
</Col>
</Form>
</Card>
  );
};
export default UpdateEmployee;
